import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function InvoiceSection({
  requestFromFirebase,
  workersWage,
  partsCost,
  total,
  discount,
  vatValue,
  moneyTakenFromCustomerCredits,
  totalAfterDiscount,
  deliveryCostOfParts,
}) {
  const { t } = useTranslation('common');

  return (
    <Col xs={10} md={10} className="p-0 pb-2 pt-2">
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.workerWage')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.workersWage || workersWage} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.partsCost')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.partsCost || partsCost} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.vatValue')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.vatValue || vatValue} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.deliveryCostOfParts')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.deliveryCostOfParts || deliveryCostOfParts} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.total')}</span>
        </Col>
        <Col className="left strikethrough">
          <span className="mb-2 text-muted">
            {requestFromFirebase.total || total} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.discount')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.discount || discount} {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="sep">
        <Col>
          <span>{t('RequestDetailsPage.creditsTakenFromCustomerWallet')}</span>
        </Col>
        <Col xs={3} md={3} className="left pr-0">
          <span className="mb-2 text-muted">
            {requestFromFirebase.moneyTakenFromCustomerCredits || moneyTakenFromCustomerCredits}
            {t('currency')}
          </span>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <span>{t('RequestDetailsPage.totalAfterDiscount')}</span>
        </Col>
        <Col className="left">
          <span className="mb-2 text-muted">
            {requestFromFirebase.totalAfterDiscount || totalAfterDiscount} {t('currency')}
          </span>
        </Col>
      </Row>
    </Col>
  );
}
