import { requestsConstants } from '../../constants';
import { requestsService } from '../../services';
import { orderNewService } from './services.actions';
import { Status } from '../../services/api';

function getAllCustomerRequests(type) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.getAllCustomerRequests(type);

    if (Status.isSuccess(response.status)) {
      return dispatch(success(response.response));
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.GET_ALL_ACTIVE_REQUESTS_REQUEST };
  }

  function success(requests) {
    return { type: requestsConstants.GET_ALL_ACTIVE_REQUESTS_SUCCESS, requests };
  }

  function failure(error) {
    return { type: requestsConstants.GET_ALL_ACTIVE_REQUESTS_FAILURE, error };
  }
}

function cancelRequest(data, history) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.cancelRequest(data);

    if (Status.isSuccess(response.status)) {
      dispatch(success());
      return history.push('/');
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.CANCEL_REQUEST_REQUEST };
  }

  function success() {
    return { type: requestsConstants.CANCEL_REQUEST_SUCCESS };
  }

  function failure(error) {
    return { type: requestsConstants.CANCEL_REQUEST_FAILURE, error };
  }
}

function cancelActiveRequest(data, history) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.cancelRequest({
      requestId: data.request._id,
      cancellationReason: data.cancellationReason,
      cancellationReasonComment: data.cancellationReasonComment,
    });

    if (Status.isSuccess(response.status)) {
      dispatch(success());

      if (data.field) {
        const newServiceRequestData = {
          field: data.field,
          location: {
            latitude: data.request.location.latitude,
            longitude: data.request.location.longitude,
          },
        };

        if (data.request.promoCode) {
          newServiceRequestData.promoCode = data.request.promoCode.code;
        }

        return dispatch(orderNewService(newServiceRequestData, history));
      }

      return history.push('/');
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.CANCEL_ACTIVE_REQUEST_REQUEST };
  }

  function success() {
    return { type: requestsConstants.CANCEL_ACTIVE_REQUEST_SUCCESS };
  }

  function failure(error) {
    return { type: requestsConstants.CANCEL_ACTIVE_REQUEST_FAILURE, error };
  }
}

function getRequestDetailsByPrettyId(id) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.getRequestDetailsByPrettyId(id);

    if (Status.isSuccess(response.status)) {
      return dispatch(success(response.response));
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.GET_REQUEST_DETAILS_REQUEST };
  }

  function success(request) {
    return { type: requestsConstants.GET_REQUEST_DETAILS_SUCCESS, request };
  }

  function failure(error) {
    return { type: requestsConstants.GET_REQUEST_DETAILS_FAILURE, error };
  }
}

function getRequestDetailsByPrettyIdFromFirebase(requestPrettyId) {
  return async (dispatch) => {
    dispatch(request());

    const ref = await requestsService.getRequestDetailsByPrettyIdFromFireBase(requestPrettyId);

    ref.on(
      'value',
      (snapshot) => {
        const data = snapshot.val();
        dispatch(success(data));
      },
      (errorObject) => {
        console.log(`The read failed: ${errorObject}`);
        console.log(`The read failed: ${errorObject.code}`);
        return dispatch(failure(errorObject.code));
      },
    );
  };

  function request() {
    return { type: requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_REQUEST };
  }

  function success(newRequest) {
    return {
      type: requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_SUCCESS,
      request: newRequest,
    };
  }

  function failure(error) {
    return {
      type: requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_FAILURE,
      error,
    };
  }
}

function confirmTheWorkerRequestToPostponeTheRequest(requestId, confirmationStatus) {
  return async (dispatch) => {
    dispatch(request());

    const response = await requestsService.confirmTheWorkerRequestToPostponeTheRequest(
      requestId,
      confirmationStatus,
    );

    if (Status.isSuccess(response.status)) {
      return dispatch(success());
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_REQUEST };
  }

  function success() {
    return {
      type: requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_FAILURE,
      error,
    };
  }
}

function createOrderForTheInvoiceInStcPay(requestId, sessionToken) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.createOrderForTheInvoiceInStcPay(
      requestId,
      sessionToken,
    );

    if (Status.isSuccess(response.status)) {
      const {
        response: { stcPayPaymentRef },
      } = response;
      return dispatch(success(stcPayPaymentRef));
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_REQUEST };
  }

  function success(stcPayPaymentRef) {
    return {
      type: requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_SUCCESS,
      stcPayPaymentRef,
    };
  }

  function failure(error) {
    return { type: requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_FAILURE, error };
  }
}

function clear() {
  return { type: requestsConstants.CLEAR };
}

function confirmOrderInStcPay(stcPayPaymentRef, merchantOrderNo, history) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.confirmOrderInStcPay(stcPayPaymentRef, merchantOrderNo);

    if (Status.isSuccess(response.status)) {
      const { response: requestObject } = response;
      dispatch(success());
      clear();

      return history.push(`/request-details/${requestObject.requestPrettyId}`);
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.CONFIRM_ORDER_IN_STC_PAY_REQUEST };
  }

  function success() {
    return { type: requestsConstants.CONFIRM_ORDER_IN_STC_PAY_SUCCESS };
  }

  function failure(error) {
    return { type: requestsConstants.CONFIRM_ORDER_IN_STC_PAY_FAILURE, error };
  }
}

function rejectTheInvoice(requestId) {
  return async (dispatch) => {
    dispatch(request());
    const response = await requestsService.rejectTheInvoice(requestId);

    if (Status.isSuccess(response.status)) {
      return dispatch(success());
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: requestsConstants.REJECT_THE_INVOICE_REQUEST };
  }

  function success() {
    return { type: requestsConstants.REJECT_THE_INVOICE_SUCCESS };
  }

  function failure(error) {
    return { type: requestsConstants.REJECT_THE_INVOICE_FAILURE, error };
  }
}

export const requestsActions = {
  cancelRequest,
  cancelActiveRequest,
  getAllCustomerRequests,
  getRequestDetailsByPrettyId,
  getRequestDetailsByPrettyIdFromFirebase,
  confirmTheWorkerRequestToPostponeTheRequest,
  createOrderForTheInvoiceInStcPay,
  confirmOrderInStcPay,
  rejectTheInvoice,
};
