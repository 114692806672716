import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import HeaderComponent from '../_shared/Header';

export default function AboutUsComponent(props) {
  const { i18n } = useTranslation('common');
  const { history } = props;

  if (i18n.language === 'ar') {
    return (
      <flex>
        <div className="outer">
          <div className="two backgroundImage white-active p-2">
            <HeaderComponent history={history} isUseBackgroundImage />
            <p>فني تطبيق سهل لك حياتك..</p>
            <p>
              مع فني ما يحتاج تنزل تدور فني صيانة منزلية بعد اليوم، كل اللي عليك تفتح التطبيق، تختار
              الخدمة وتطلب الفني المناسب لك حسب تقييمه او موقعه ، و الفني راح يجيك لين عتبة دارك
              وبكذا انتهينا و مشكلتك انحلت.
            </p>
            <p>
              تطبيق فني يوفر لك جميع أنواع خدمات الصيانة المنزلية اللي ممكن بيتك يحتاجها: من تكييف،
              سباكة، نجارة، كهرباء، دهانات، نقل أثاث، مكافحة حشرات وغيرها الكثير.
            </p>
            <p>ليش تطلب عن طريق فني؟</p>
            <p>*فني يوفر لك كشف مجاني عن الأعطال.</p>
            <p>* ضمان 30 يوم على جميع الخدمات.</p>
            <p>*مشوار الفني علينا.</p>
            <p>*جودة ودقة في العمل.</p>
            <p> * ضمان أفضل الأسعار. هذا بالاضافة الى خدمة عملاء متابعين لكل</p>
            <p> مراحل طلبك. وش تبي بعد..؟</p>
          </div>
        </div>
      </flex>
    );
  }

  return (
    <flex>
      <div className="outer">
        <div className="two backgroundImage white-active p-2">
          <HeaderComponent history={history} isUseBackgroundImage />
          <p>
            <p>Thanks for being interested to know about us!</p>
            <p>We are trying to make your life easier.. as simple as that.</p>
            <p>
              With us you don't need anymore to take an effort to look for a maintenance man to fix
              home problems. Now. with two simple steps, all you need to do, is picking a service
              from our app and get a Fanni's man.
            </p>
            <p>We provide you all the home maintenance services.</p>
            <p>In addition to:</p>
            <p>* Free maintenance detection * 30 days work warranty for all services</p>
            <p>* Quality and accuracy work * Fair price </p>
            <p>* Customer care follows up your request progress, step by step.</p>
            <p>Sounds good right?</p>
          </p>
        </div>
      </div>
    </flex>
  );
}
