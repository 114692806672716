import { signUpConstants } from '../../constants';

const initialState = {
  user: undefined,
  signUp: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const { user, err } = payload;

  switch (type) {
    case signUpConstants.SIGN_UP: {
      return {
        ...state,
        signUp: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    }
    case signUpConstants.SIGN_UP_SUCCESS: {
      return {
        ...state,
        user,
        signUp: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    }
    case signUpConstants.SIGN_UP_FAIL: {
      return {
        ...state,
        signUp: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
