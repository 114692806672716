import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { signUpCustomer } from '../../store/actions';
import SignUpComponent from '../../components/SignUp';
import { Cities } from '../../locales/en/common.json';

class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: { value: '', isValid: true, message: { ar: '', en: '' } },
      email: { value: '', isValid: true, message: { ar: '', en: '' } },
      birthday: { value: '', isValid: true, message: { ar: '', en: '' } },
      city: { value: '', isValid: true, message: { ar: '', en: '' } },
      gender: { value: '', isValid: true, message: { ar: '', en: '' } },
      termsAndConditions: { value: false, isValid: true, message: { ar: '', en: '' } },
      validated: true,
    };
    const { username, history } = this.props;

    if (!username) {
      return history.push('/login');
    }
  }

  onChangeInputValue = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: {
          ...this.state[e.target.name],
          value: e.target.value,
        },
      },
      () => {
        console.log('this.stateeeeeeeeeee', this.state);
      },
    );
  };

  onChangeCheckbox = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.checked,
      },
    });
  };

  formIsValid() {
    const { name, email, birthday, city, gender, termsAndConditions } = this.state;
    let isFormValid = true;

    if (!validator.isByteLength(name.value, { min: 3, max: 15 })) {
      isFormValid = false;
      name.isValid = false;
      name.message = {
        ar: 'برجاء ادخال الاسم بحد ادنى 3 احرف و حد اقصى 15 حرف',
        en: 'Name Must be at least 3 characters and max 15 character',
      };
    }

    if (!validator.isEmail(email.value)) {
      isFormValid = false;
      email.isValid = false;
      email.message = { ar: 'برجاء ادخال بريد الكترونى صحيح', en: 'Enter A valid Email' };
    }

    if (!validator.isDate(birthday.value, 'YYYY-MM-DD')) {
      isFormValid = false;
      birthday.isValid = false;
      birthday.message = { ar: 'برجاء ادخال تاريخ الميلاد', en: 'Enter A Valid Birthday' };
    }

    if (!validator.isIn(city.value, Object.values(Cities))) {
      isFormValid = false;
      city.isValid = false;
      city.message = { ar: 'برجاء اختيار المدينة', en: 'Choose A Valid City From The List' };
    }

    if (!validator.isIn(gender.value, ['male', 'female'])) {
      isFormValid = false;
      gender.isValid = false;
      gender.message = { ar: 'برجاء اختيار النوع', en: 'Choose A Valid Gender' };
    }

    if (!validator.equals(termsAndConditions.value.toString(), 'true')) {
      isFormValid = false;
      termsAndConditions.isValid = false;
      termsAndConditions.message = {
        ar: 'برجاء الموافقة على الشروط و الاحكام',
        en: 'Please, Agree On The Terms And Conditions',
      };
    }

    if (!isFormValid) {
      this.setState({
        name,
        email,
        birthday,
        city,
        gender,
        termsAndConditions,
      });
    }

    return isFormValid;
  }

  resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(this.state));

    /*
    loop through each item in state and if it's safe to assume that only
    form values have an 'isValid' property, we can use that to reset their
    validation states and keep their existing value property. This process
    makes it easy to set all validation states on form inputs in case the number
    of fields on our form grows in the future.
    */
    Object.keys(state).map((key) => {
      if (state[key].hasOwnProperty('isValid')) {
        state[key].isValid = true;
        state[key].message = { ar: '', en: '' };
      }
    });

    return state;
  };

  handleSubmitSignUpForm = (e) => {
    e.preventDefault();
    const { name, email, birthday, city, gender } = this.state;
    const { username, history, signUpCustomer: signUpCustomerAction } = this.props;

    // reset states before the validation procedure is run.
    const restValidationState = this.resetValidationStates();
    this.setState(restValidationState, () => {
      if (this.formIsValid()) {
        signUpCustomerAction(
          {
            username,
            name: name.value,
            email: email.value,
            birthday: birthday.value,
            city: city.value,
            gender: gender.value,
          },
          history,
        );
      }
    });
  };

  render() {
    const { name, email, birthday, city, gender, termsAndConditions } = this.state;
    const { isFetchingSignUp, isSuccessSignUp, isErrorSignUp, signUpErrorMessage } = this.props;

    return (
      <SignUpComponent
        name={name}
        email={email}
        birthday={birthday}
        city={city}
        gender={gender}
        termsAndConditions={termsAndConditions}
        isFetchingSignUp={isFetchingSignUp}
        isSuccessSignUp={isSuccessSignUp}
        isErrorSignUp={isErrorSignUp}
        signUpErrorMessage={signUpErrorMessage}
        handleSubmitSignUpForm={this.handleSubmitSignUpForm}
        onChangeInputValue={this.onChangeInputValue}
        onChangeCheckbox={this.onChangeCheckbox}
      />
    );
  }
}

SignUpContainer.displayName = 'SignUpContainer';

const mapStateToProps = (state) => ({
  username: state.login.username,
  isFetchingSignUp: state.signUp.signUp.isFetching,
  isSuccessSignUp: state.signUp.signUp.isSuccess,
  isErrorSignUp: state.signUp.signUp.isFail.isError,
  signUpErrorMessage: state.signUp.signUp.isFail.message,
});

const actionCreators = {
  signUpCustomer,
};

export default connect(mapStateToProps, actionCreators)(SignUpContainer);
