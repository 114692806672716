import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import store from './store';

import AR from './locales/ar/common.json';
import EN from './locales/en/common.json';

import 'bootstrap/dist/css/bootstrap.css';

import AppContainer from './containers/App';
import * as serviceWorker from './serviceWorker';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'ar',
  resources: {
    en: {
      common: EN,
    },
    ar: {
      common: AR,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <Route component={AppContainer} />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
// document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");

serviceWorker.unregister();
