import { combineReducers } from 'redux';

import services from './services.reducer';
import { appliedWorkers } from './appliedWorkers.reducer';
import { requests } from './requests.reducer';
import { users } from './users.reducer';
import login from './login.reducer';
import otp from './otp.reducer';
import signUp from './signUp.reducer';

const rootReducer = combineReducers({
  services,
  appliedWorkers,
  requests,
  users,
  login,
  otp,
  signUp,
});

export default rootReducer;
