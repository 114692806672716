import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Header.css';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HeaderComponent(props) {
  const { t } = useTranslation('common');
  const { history, isUseBackgroundImage } = props;

  return (
    <Row
      className={
        isUseBackgroundImage ? 'f-header p-0 pl-2 pr-2 backgroundImage' : 'f-header p-0 pl-2 pr-2'
      }
    >
      <Col xs={4} />
      <Col xs={4} className="f-name text-center m-0 p-0">
        <h1 className="m-0">{t('AppName')}</h1>
      </Col>
      <Col
        xs={4}
        className="f-back-btn m-0 p-0 d-flex justify-content-end"
        onClick={() => {
          history.goBack();
        }}
      >
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="lg"
          key="backBtn"
          onClick={() => {
            history.goBack();
          }}
        />
      </Col>
    </Row>
  );
}
