import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faMapMarkerAlt, faMedal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './AppliedWorkerCard.css';

export default function AppliedWorkerCardComponent(props) {
  const {
    worker: { _id, name, averageRating },
    acceptWorker,
    isFetchingAcceptWorker,
    // isErrorAcceptWorker,
    // acceptWorkerErrorMessage,
  } = props;
  const { t } = useTranslation('common');

  const drawRating = (rating) => {
    const stars = [];
    rating = parseInt(rating);
    for (let i = rating; i < 5; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} size="sm" key={i * 2} />);
    }
    for (let i = 1; i <= rating; i++) {
      stars.push(
        <FontAwesomeIcon
          className="checked"
          style={{ color: '#3db87f' }}
          key={i * 3}
          icon={faStar}
          size="sm"
        />,
      );
    }
    return stars;
  };

  return (
    <Card className="text-center m-2">
      <Card.Body>
        <Row>
          <Col>
            <Card.Text>
              <Link className="black-color" to={`/worker-details/${_id}`}>
                {name}
              </Link>
              <br />
              <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
              {Math.round(averageRating * 10) / 10}
            </Card.Text>
          </Col>
          <Col>
            <FontAwesomeIcon className="medal" icon={faMedal} size="2x" />
          </Col>
          <Col>
            <Card.Text>{drawRating(averageRating)}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Button
        variant=""
        className="ml-3 mr-3 mb-3 gray-background"
        type="submit"
        disabled={isFetchingAcceptWorker}
        onClick={() => acceptWorker(_id)}
        data-user={_id}
      >
        {isFetchingAcceptWorker
          ? t('ChooseWorkerPage.acceptWorkerLoadingButtonText')
          : t('ChooseWorkerPage.acceptWorkerButtonText')}
      </Button>
    </Card>
  );
}
