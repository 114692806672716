export const requestsConstants = {
  GET_ALL_ACTIVE_REQUESTS_REQUEST: 'GET_ALL_ACTIVE_REQUESTS_REQUEST',
  GET_ALL_ACTIVE_REQUESTS_SUCCESS: 'GET_ALL_ACTIVE_REQUESTS_SUCCESS',
  GET_ALL_ACTIVE_REQUESTS_FAILURE: 'GET_ALL_ACTIVE_REQUESTS_FAILURE',

  CANCEL_REQUEST_REQUEST: 'CANCEL_REQUEST_REQUEST',
  CANCEL_REQUEST_SUCCESS: 'CANCEL_REQUEST_SUCCESS',
  CANCEL_REQUEST_FAILURE: 'CANCEL_REQUEST_FAILURE',

  CANCEL_ACTIVE_REQUEST_REQUEST: 'CANCEL_ACTIVE_REQUEST_REQUEST',
  CANCEL_ACTIVE_REQUEST_SUCCESS: 'CANCEL_ACTIVE_REQUEST_SUCCESS',
  CANCEL_ACTIVE_REQUEST_FAILURE: 'CANCEL_ACTIVE_REQUEST_FAILURE',

  GET_REQUEST_DETAILS_REQUEST: 'GET_REQUEST_DETAILS_REQUEST',
  GET_REQUEST_DETAILS_SUCCESS: 'GET_REQUEST_DETAILS_SUCCESS',
  GET_REQUEST_DETAILS_FAILURE: 'GET_REQUEST_DETAILS_FAILURE',

  GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_REQUEST:
    'GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_REQUEST',
  GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_SUCCESS:
    'GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_SUCCESS',
  GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_FAILURE:
    'GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_FAILURE',

  CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_REQUEST:
    'CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_REQUEST',
  CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_SUCCESS:
    'CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_SUCCESS',
  CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_FAILURE:
    'CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_FAILURE',

  CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_REQUEST:
    'CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_REQUEST',
  CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_SUCCESS:
    'CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_SUCCESS',
  CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_FAILURE:
    'CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_FAILURE',

  CONFIRM_ORDER_IN_STC_PAY_REQUEST: 'CONFIRM_ORDER_IN_STC_PAY_REQUEST',
  CONFIRM_ORDER_IN_STC_PAY_SUCCESS: 'CONFIRM_ORDER_IN_STC_PAY_SUCCESS',
  CONFIRM_ORDER_IN_STC_PAY_FAILURE: 'CONFIRM_ORDER_IN_STC_PAY_FAILURE',

  REJECT_THE_INVOICE_REQUEST: 'REJECT_THE_INVOICE_REQUEST',
  REJECT_THE_INVOICE_SUCCESS: 'REJECT_THE_INVOICE_SUCCESS',
  REJECT_THE_INVOICE_FAILURE: 'REJECT_THE_INVOICE_FAILURE',

  CLEAR: 'CLEAR_ACTIVE_REQUESTS',
};
