import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RequestCard from '../RequestCard';

import './ActiveRequests.css';

export default function ActiveRequestsComponent(props) {
  const { t, i18n } = useTranslation('common');

  const {
    requests,
    cardClicked,
    isFetchingGetActiveRequestsList,
    isErrorGetActiveRequestsList,
    getActiveRequestsListErrorMessage,
  } = props;

  if (isFetchingGetActiveRequestsList) {
    return (
      <Row className="p-2 text-center">
        <Col className="p-1">
          <Image src={`${process.env.PUBLIC_URL}/images/chooseGif.gif`} width={200} height={200} />
        </Col>
      </Row>
    );
  }

  if (!requests.length) {
    return (
      <Row className="p-2 text-center">
        <Col xs={12} md={12} className="p-1">
          <Image src={`${process.env.PUBLIC_URL}/images/spinner.gif`} width={200} height={200} />
        </Col>
        <Col className="p-2" xs={12} md={12}>
          <span>{t('RequestsPage.noRequestsFound')}</span>
        </Col>
        <Col className="p-2" xs={12} md={12}>
          <Link to="/" className="btn btn-lg btn-fanni">
            {t('RequestsPage.orderNewServiceBtnText')}
          </Link>
        </Col>
      </Row>
    );
  }

  if (isErrorGetActiveRequestsList) {
    return (
      <div className="text-center">
        <span className="text-danger">{getActiveRequestsListErrorMessage[i18n.language]}</span>
      </div>
    );
  }

  return (
    <>
      {requests.map((request) => (
        <RequestCard request={request} key={request._id} onClick={cardClicked} />
      ))}
    </>
  );
}
