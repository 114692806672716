import ls from 'local-storage';
import { Api, Status, Urls } from '../../services/api';
import { otpConstants } from '../../constants';

const login = (credentials, historyPush) => ({
  type: otpConstants.LOGIN,
  credentials,
  historyPush,
});

const loginSuccess = (user) => ({
  type: otpConstants.LOGIN_SUCCESS,
  user,
});

const loginFail = (err) => ({
  type: otpConstants.LOGIN_FAIL,
  err,
});

export const loginUser = function (credentials, historyPush) {
  return async (dispatch) => {
    dispatch(login(credentials, historyPush));
    const { user } = Urls;
    const url = user.login;
    const api = new Api();

    const response = await api.post(url, credentials);

    if (Status.isSuccess(response.status)) {
      const {
        response: { plainUser, token },
      } = response;
      ls.set('token', token);
      dispatch(loginSuccess(plainUser));

      return historyPush.push('/');
    }

    const { response: message } = response;

    return dispatch(loginFail(message));
  };
};
