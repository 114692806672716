import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import './CancellationReasonModal.css';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function CancellationReasonModalComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    isModelOpen,
    handleClose,
    isReasonCommentOpen,
    cancellationReasonComment,
    handelReasonCommentChange,
    cancelRequest,
    handelReasonSelect,
    appliedWorkers,
    configs,
    configs: {
      enums: {
        cancellingPendingRequestsWithAppliedWorkers: withAppliedWorkersReasons,
        cancellingPendingRequestsWithoutAppliedWorkers: withoutAppliedWorkersReasons,
      },
    },
    isFetchingCancelRequest,
    isErrorCancelRequest,
    cancelRequestErrorMessage,
  } = props;

  const renderModalBody = () => {
    if (appliedWorkers.length && !_.isEmpty(configs)) {
      return withAppliedWorkersReasons.map((reason) => (
        <div key={`inline-radio-${reason.code}`} className="mb-3">
          <Form.Check
            inline
            label={reason.messages[i18n.language]}
            type="radio"
            className="mr-3"
            id={`${reason.code}`}
            name="reason"
            data-code={reason.code}
            onClick={handelReasonSelect}
          />
        </div>
      ));
    }

    if (!appliedWorkers.length && !_.isEmpty(configs)) {
      return withoutAppliedWorkersReasons.map((reason) => (
        <div key={`inline-radio-${reason.code}`} className="mb-3">
          <Form.Check
            inline
            label={reason.messages[i18n.language]}
            type="radio"
            className="mr-3"
            id={`${reason.code}`}
            name="reason"
            data-code={reason.code}
            onClick={handelReasonSelect}
          />
        </div>
      ));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModelOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Form>
          {renderModalBody()}
          <Form.Group
            controlId="111"
            className={isReasonCommentOpen ? 'showReasonComment' : 'hideReasonComment'}
          >
            <Form.Label>{t('ChooseWorkerPage.cancellationReasonModal.headerText')} </Form.Label>
            <Form.Control
              value={cancellationReasonComment}
              onChange={handelReasonCommentChange}
              as="textarea"
              rows="3"
            />
          </Form.Group>
        </Form>
        {isErrorCancelRequest && (
          <span className="text-danger">{cancelRequestErrorMessage[i18n.language]}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isFetchingCancelRequest}>
          {t('ChooseWorkerPage.cancellationReasonModal.cancelButtonText')}
        </Button>
        <Button variant="danger" onClick={cancelRequest} disabled={isFetchingCancelRequest}>
          {t('ChooseWorkerPage.cancellationReasonModal.confirmButtonText')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
CancellationReasonModalComponent.displayName = 'CancellationReasonModalComponent';

export default CancellationReasonModalComponent;
