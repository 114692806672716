import React from 'react';

import './HistoryRequests.css';
import { useTranslation } from 'react-i18next';

export default function HistoryRequests() {
  const { t } = useTranslation('common');

  return (
    <div className="center-items">
      <div>
        <span>{t('historyRequestsPage.downloadOurAppText')}</span>
        <a className="btn btn-block btn-lg btn-fanni pt-2" href="/">
          {t('historyRequestsPage.downloadAppBtnText')}
        </a>
      </div>
    </div>
  );
}
