import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function TopSection({ status, requestFromFirebase, worker, createdAt, promoCode }) {
  const { t, i18n } = useTranslation('common');

  const statusColor = {
    pending: '#6a0dad',
    active: '#0000CD',
    postponed: '',
    canceled: '#ac0000',
    'timed-out': '#ac0000',
    done: '#3db87f',
    reviewed: '#FFBD33',
  };

  return (
    <>
      <Row className="pt-3 text-center">
        <Col xs={2} className="p-0">
          <span className="pt-2 pb-2 pr-3 pl-3">{t('RequestDetailsPage.status')}</span>
        </Col>
        <Col xs={2}>
          <span
            className="mb-2"
            style={{
              color: statusColor[status],
            }}
          >
            {t(`RequestStatuses.${requestFromFirebase.status || status}`)}
          </span>
        </Col>
        <Col xs={2}>
          <span>{t('RequestDetailsPage.time')}</span>
        </Col>
        <Col>
          <span className="small">
            {new Date(createdAt).toLocaleDateString(`${i18n.language}-EG`, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </span>
        </Col>
      </Row>

      <Row className="pt-2">
        <Col xs={2} className="pr-3 pl-3">
          <FontAwesomeIcon icon={faIdCard} size="lg" />
        </Col>
        <Col className="p-0">
          <span className="">{worker && worker.name}</span>
        </Col>
        {promoCode && (
          <>
            <Col xs={3}>
              <span>{t('RequestDetailsPage.discount')}</span>
            </Col>
            <Col className="p-o">
              <span
                className="mb-2"
                style={{
                  color: '#ac0000',
                }}
              >
                {promoCode.discount.value}
                {promoCode.discount.type === 'percentage' ? '%' : t('currency')}
              </span>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}
