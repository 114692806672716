import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';

import './OrderNewRequestConfirmationModal.css';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

export default function OrderNewRequestConfirmationModalComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    confirmOrderNewRequest,
    onClickOrderNewRequestButton,
    show,
    isFetchingOrderNewService,
    isErrorOrderNewService,
    orderNewServiceErrorMessage,
  } = props;

  return (
    <Modal
      show={show}
      keyboard={false}
      size="sm"
      onHide={() => {
        onClickOrderNewRequestButton();
      }}
      dialogClassName="modal-confirm modal-dialog-centered"
      centered
    >
      <Modal.Header>
        <div className="icon-box">
          <i className="material-icons">&#xE876;</i>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ul className="list-unstyled dashed text-center">
          <li>{t('servicesPage.confirmModal.immediatelyServiceText')}</li>
          <li>{t('servicesPage.confirmModal.DetectionOnUsText')}</li>
          <li>{t('servicesPage.confirmModal.WarrantyText')}</li>
          <li className="mt-4">{t('servicesPage.confirmModal.agreementText')}</li>
          <li>{t('servicesPage.confirmModal.readyToOrderText')}</li>
        </ul>
        {isErrorOrderNewService && (
          <span className="text-danger">{orderNewServiceErrorMessage[i18n.language]}</span>
        )}
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Col xs={12} md={12} className="p-0">
          <Button
            variant="secondary"
            className="btn-block"
            disabled={isFetchingOrderNewService}
            onClick={() => {
              confirmOrderNewRequest();
            }}
          >
            {isFetchingOrderNewService && (
              <Spinner
                aria-hidden="true"
                size="sm"
                role="status"
                animation="border"
                variant="primary"
              />
            )}
            {t('servicesPage.confirmModal.agreeToOrderButtonText')}
          </Button>
        </Col>
        <Col xs={12} md={12} className="p-0">
          <Button
            variant="danger"
            className="btn-block"
            disabled={isFetchingOrderNewService}
            onClick={() => {
              onClickOrderNewRequestButton();
            }}
          >
            {t('servicesPage.confirmModal.cancellationButtonText')}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
