import React from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './CancelRequestModal.css';

export default function TechnicianWasNoShowModal(props) {
  const {
    showTechnicianWasNoShowModalReasonModal,
    toggleTheCancellationReasonModal,
    cancelTheRequestAction,
    isErrorCancelActiveRequest,
    cancelActiveRequestErrorMessage,
  } = props;
  const { t, i18n } = useTranslation('common');

  return (
    <Modal
      show={showTechnicianWasNoShowModalReasonModal}
      onHide={() => toggleTheCancellationReasonModal('showTechnicianWasNoShowModalReasonModal')}
      centered
    >
      <Modal.Header className="cancellation-reason-modal-header" closeButton={false}>
        <Modal.Title>{t('RequestDetailsPage.cancellationReasons.rushTechnician')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col xs={12} md={12}>
          {isErrorCancelActiveRequest && (
            <span className="text-danger">{cancelActiveRequestErrorMessage[i18n.language]}</span>
          )}
        </Col>
      </Modal.Body>
      <Modal.Footer className="cancellation-reason-modal-footer">
        <Button
          variant="secondary"
          onClick={() =>
            toggleTheCancellationReasonModal('showTechnicianWasNoShowModalReasonModal')
          }
        >
          {t('RequestDetailsPage.cancellationReasons.wait')}
        </Button>
        <Button variant="danger" onClick={() => cancelTheRequestAction()}>
          {t('RequestDetailsPage.cancellationReasons.confirmation')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
