import React from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import './CancelRequestModal.css';

export default function ServicesListModal(props) {
  const {
    showServicesListModal,
    toggleTheCancellationReasonModal,
    configs: {
      enums: { cancellingActiveRequestReasonsAsArray = [] },
    },
    cancelTheRequestAction,
    isErrorCancelActiveRequest,
    cancelActiveRequestErrorMessage,
  } = props;
  const { t, i18n } = useTranslation('common');
  const cancellationReason = _.find(cancellingActiveRequestReasonsAsArray, (cancellationReason) => {
    return cancellationReason.code === 'needNewField';
  });

  return (
    <Modal
      show={showServicesListModal}
      onHide={() => toggleTheCancellationReasonModal('showServicesListModal')}
      centered
    >
      <Modal.Header className="cancellation-reason-modal-header" closeButton={false}>
        <Modal.Title>{cancellationReason.messages[i18n.language]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('mobiles')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/mobiles.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.mobiles')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('reconditioning')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/reconditioning.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.reconditioning')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('paved')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/paved.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.paved')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('electrical')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/electrical.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.electrical')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('plumbing')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/plumbing.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.plumbing')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('carpentry')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/carpentry.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.carpentry')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('air-conditioning')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/air-conditioning.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.air-conditioning')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('painting')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/painting.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.painting')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('satellite-tv')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/satellite-tv.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.satellite-tv')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div
              className="circle"
              onClick={() => cancelTheRequestAction('furniture-transportation')}
            >
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/furniture-transportation.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.furniture-transportation')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('cleaning')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/cleaning.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.cleaning')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('home-appliances')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/home-appliances.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.home-appliances')}</span>
          </Col>

          <Col className="pb-3" xs={4} md={4}>
            <div className="circle" onClick={() => cancelTheRequestAction('cameras')}>
              <Image
                className="to-be-white"
                src={`${process.env.PUBLIC_URL}/images/services/cameras.png`}
                width={35}
                height={35}
              />
            </div>
            <span>{t('Fields.cameras')}</span>
          </Col>
        </Row>
        <Col xs={12} md={12}>
          {isErrorCancelActiveRequest && (
            <span className="text-danger">{cancelActiveRequestErrorMessage[i18n.language]}</span>
          )}
        </Col>
      </Modal.Body>
    </Modal>
  );
}
