import ls from 'local-storage';
import Firebase from 'firebase';
import { Api, Urls } from './api';
import { firebaseConfigs } from '../config';

const { requests } = Urls;
const api = new Api();

function getAllCustomerRequests(type) {
  const url = `${requests.getAllCustomerRequests}?requestStatusesArray=${type}&skip=0&limit=20`;
  const token = ls.get('token');
  const header = [{ key: 'token', value: token }];

  return api.get(url, header);
}

function cancelRequest(data) {
  const url = requests.cancel;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];

  return api.patch(url, data, header);
}

function getRequestDetailsByPrettyId(id) {
  const url = `${requests.getRequestDetailsByPrettyId}?requestPrettyId=${id}`;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];

  return api.get(url, header);
}

function getRequestDetailsByPrettyIdFromFireBase(requestPrettyId) {
  if (!Firebase.apps.length) {
    Firebase.initializeApp(firebaseConfigs);
  }

  const ref = Firebase.database().ref(`/requests/${requestPrettyId}`);

  return ref;
}

function confirmTheWorkerRequestToPostponeTheRequest(requestId, confirmationStatus) {
  const url = requests.confirmThePostponeRequest;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];
  const body = {
    requestId,
    confirmation: confirmationStatus,
  };
  return api.post(url, body, header);
}

function createOrderForTheInvoiceInStcPay(requestId, sessionToken) {
  const url = requests.createOrderForTheInvoiceInStcPayUrl;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];
  const body = {
    requestId,
    sessionToken,
  };
  return api.post(url, body, header);
}

function confirmOrderInStcPay(stcPayPaymentRef, merchantOrderNo) {
  const url = requests.confirmOrderInStcPayUrl;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];
  const body = {
    stcPayPaymentRef,
    merchantOrderNo,
  };
  return api.post(url, body, header);
}

function rejectTheInvoice(requestId) {
  const url = requests.rejectTheInvoiceUrl;
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];
  const body = {
    requestId,
    paymentStatus: 'rejected',
  };
  return api.patch(url, body, header);
}

export const requestsService = {
  getAllCustomerRequests,
  cancelRequest,
  getRequestDetailsByPrettyId,
  getRequestDetailsByPrettyIdFromFireBase,
  confirmTheWorkerRequestToPostponeTheRequest,
  createOrderForTheInvoiceInStcPay,
  confirmOrderInStcPay,
  rejectTheInvoice,
};
