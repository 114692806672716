import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestsActions, usersActions } from '../../store/actions';
import RateWorkerComponent from '../../components/RateWorker';

class RateWorkerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 5,
      ratingComment: '',
      reasons: {
        good: ['quick', 'responsive', 'goodBehaviour', 'goodHygiene', 'onTime', 'helpful'],
        bad: [
          'delay',
          'notResponsive',
          'talkative',
          'slow',
          'lowBehaviour',
          'badhygiene',
          'notCommittedToPrices',
        ],
      },
      quick: 0,
      responsive: 0,
      goodBehaviour: 0,
      goodHygiene: 0,
      onTime: 0,
      helpful: 0,
      delay: 0,
      notResponsive: 0,
      talkative: 0,
      slow: 0,
      lowBehaviour: 0,
      badhygiene: 0,
      notCommittedToPrices: 0,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id: requestPrettyId },
      },
      getRequestDetailsByPrettyId,
    } = this.props;

    getRequestDetailsByPrettyId(requestPrettyId);
  }

  handleSubmit = () => {
    const {
      requestDetails: {
        _id: requestId,
        requestPrettyId,
        worker: { _id: workerId },
      },
      rateWorker,
      history,
    } = this.props;

    const {
      rating,
      delay,
      notResponsive,
      talkative,
      slow,
      lowBehaviour,
      badhygiene,
      notCommittedToPrices,
      quick,
      responsive,
      goodBehaviour,
      goodHygiene,
      onTime,
      helpful,
    } = this.state;

    const payload = {
      requestId,
      workerId,
      rating,
      delay,
      notResponsive,
      talkative,
      slow,
      lowBehaviour,
      badhygiene,
      notCommittedToPrices,
      quick,
      responsive,
      goodBehaviour,
      goodHygiene,
      onTime,
      helpful,
    };

    rateWorker(payload, requestPrettyId, history);
  };

  handleRatingChange = (rating) => {
    this.setState({ rating });

    if (rating > 3)
      this.setState({
        delay: 0,
        notResponsive: 0,
        talkative: 0,
        slow: 0,
        lowBehaviour: 0,
        badhygiene: 0,
        notCommittedToPrices: 0,
      });
    else
      this.setState({
        quick: 0,
        responsive: 0,
        goodBehaviour: 0,
        goodHygiene: 0,
        onTime: 0,
        helpful: 0,
      });
  };

  handelReasonSelect = (e) => {
    const reasonName = e.target.value;
    this.state[reasonName]
      ? this.setState({ [reasonName]: 0 })
      : this.setState({ [reasonName]: 1 });
  };

  handelRatingCommentChange = (e) => this.setState({ ratingComment: e.target.value });

  render() {
    const { rating, reasons, ratingComment } = this.state;
    const { history } = this.props;
    return (
      <RateWorkerComponent
        rating={rating}
        reasons={reasons}
        ratingComment={ratingComment}
        handelReasonSelect={this.handelReasonSelect}
        handelRatingCommentChange={this.handelRatingCommentChange}
        handleRatingChange={this.handleRatingChange}
        handleSubmit={this.handleSubmit}
        history={history}
      />
    );
  }
}

RateWorkerContainer.displayName = 'RateWorkerContainer';

function mapStateToProps(state) {
  return {
    isFetchingGetRequestDetails: state.requests.getRequestDetails.isFetching,
    isErrorGetRequestDetails: state.requests.getRequestDetails.isFail.isError,
    getRequestDetailsErrorMessage: state.requests.getRequestDetails.isFail.message,
    requestDetails: state.requests.details,
  };
}

const actionCreators = {
  getRequestDetailsByPrettyId: requestsActions.getRequestDetailsByPrettyId,
  rateWorker: usersActions.rateWorker,
};

export default connect(mapStateToProps, actionCreators)(RateWorkerContainer);
