import { Col, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function ArrivalActivitySection({ arrivalActivity }) {
  const { t } = useTranslation('common');

  const isWorkerOnHisWay = _.find(arrivalActivity, (activity) => {
    return activity.status === 'StartMoving';
  });
  const isWorkerArrived = _.find(arrivalActivity, (activity) => {
    return activity.status === 'Arrived';
  });

  return (
    <>
      <Row className="pt-2 pr-3 pl-3 small">
        <span className="text-danger">{t('RequestDetailsPage.guaranteeSupportText')}</span>
      </Row>

      <Row className="pt-3 pr-3 pl-3 text-center">
        <Col xs={3} className={`p-0 ${isWorkerOnHisWay ? 'arrival-activity-active' : ''}`}>
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          <br />
          <span>في الطريق</span>
        </Col>
        <Col className="p-0">
          <hr id="line" />
        </Col>
        <Col xs={3} className={`p-0 ${isWorkerArrived ? 'arrival-activity-active' : ''}`}>
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          <br />
          <span>وصل</span>
        </Col>
      </Row>

      <Row className="p-2 text-center">
        <Col className="p-1">
          <Image src={`${process.env.PUBLIC_URL}/images/spinner.gif`} width={200} height={200} />
        </Col>
      </Row>
    </>
  );
}
