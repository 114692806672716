import ls from 'local-storage';
import { Api, Status, Urls } from '../../services/api';
import { servicesConstants } from '../../constants';

const addNewService = () => ({
  type: servicesConstants.ADD_NEW_REQUEST,
});

const addNewRequestSuccess = (request) => ({
  type: servicesConstants.ADD_NEW_REQUEST_SUCCESS,
  request,
});

const addNewRequestFail = (err) => ({
  type: servicesConstants.ADD_NEW_REQUEST_FAILED,
  err,
});

const validatePromoCode = (payload) => ({
  type: servicesConstants.USE_PROMO_CODE,
  payload,
});

const validatePromoCodeSuccess = (promoCode) => ({
  type: servicesConstants.USE_PROMO_CODE_SUCCESS,
  promoCode,
});

const validatePromoCodeFail = (err) => ({
  type: servicesConstants.USE_PROMO_CODE_FAILED,
  err,
});

const getAds = () => ({
  type: servicesConstants.GET_CUSTOMER_ADS,
});

const getAdsSuccess = (ads) => ({
  type: servicesConstants.GET_CUSTOMER_ADS_SUCCESS,
  ads,
});

const getAdsFail = (err) => ({
  type: servicesConstants.GET_CUSTOMER_ADS_FAILED,
  err,
});

const getConfigurations = () => ({
  type: servicesConstants.GET_ALL_CONFIGS,
});

const getConfigurationsSuccess = (configs) => ({
  type: servicesConstants.GET_ALL_CONFIGS_SUCCESS,
  configs,
});

const getConfigurationsFail = (err) => ({
  type: servicesConstants.GET_ALL_CONFIGS_FAIL,
  err,
});

export const validateThePromoCodeForUsage = function (code) {
  return async (dispatch) => {
    dispatch(validatePromoCode(code));
    const { promoCodes } = Urls;
    const url = `${promoCodes.getByPromoCode}/${code}`;
    const token = ls.get('token');
    const api = new Api();
    const header = [
      {
        key: 'token',
        value: token,
      },
    ];
    const response = await api.get(url, header);

    if (Status.isSuccess(response.status)) {
      const { response: promoCode } = response;
      return dispatch(validatePromoCodeSuccess(promoCode));
    }

    const { response: message } = response;

    return dispatch(validatePromoCodeFail(message));
  };
};

export const orderNewService = function (data, history) {
  return async (dispatch) => {
    dispatch(addNewService());
    const { requests } = Urls;
    const url = `${requests.general}`;
    const api = new Api();
    const token = ls.get('token');
    const header = [
      {
        key: 'token',
        value: token,
      },
    ];

    const response = await api.post(url, data, header);

    if (Status.isSuccess(response.status)) {
      const {
        response: { requestPrettyId },
        response: request,
      } = response;
      dispatch(addNewRequestSuccess(request));
      return history.push(`/choose-worker/${requestPrettyId}`);
    }

    const { response: message } = response;

    return dispatch(addNewRequestFail(message));
  };
};

export const getAllCustomerAds = function () {
  return async (dispatch) => {
    dispatch(getAds());
    const { customersAds } = Urls;
    const url = `${customersAds.getAllCustomersAds}`;
    const token = ls.get('token');
    const api = new Api();
    const header = [
      {
        key: 'token',
        value: token,
      },
    ];

    const response = await api.get(url, header);

    if (Status.isSuccess(response.status)) {
      const { response: ads } = response;
      return dispatch(getAdsSuccess(ads));
    }

    const { response: message } = response;

    return dispatch(getAdsFail(message));
  };
};

export const getAllConfigurations = function () {
  return async (dispatch) => {
    dispatch(getConfigurations());
    const { configs } = Urls;
    const url = `${configs.url}`;
    const token = ls.get('token');
    const api = new Api();
    const header = [
      {
        key: 'token',
        value: token,
      },
    ];

    const response = await api.get(url, header);

    if (Status.isSuccess(response.status)) {
      const { response: configurations } = response;
      return dispatch(getConfigurationsSuccess(configurations));
    }

    const { response: message } = response;

    return dispatch(getConfigurationsFail(message));
  };
};

export const clearServicesStore = () => ({
  type: servicesConstants.CLEAR,
});
