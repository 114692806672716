import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function RequestInfoHeaderComponent(props) {
  const { field, requestPrettyId } = props;
  const { t } = useTranslation('common');

  return (
    <Row
      className="p-3 pl-4 pr-4 text-center"
      style={{ backgroundColor: '#23364a', color: 'white' }}
    >
      <Col xs={5} className="p-0">
        <Image
          className="to-be-white"
          src={`${process.env.PUBLIC_URL}/images/services/${field}.png`}
          width={25}
          height={25}
        />
        <span className="pr-3">{t(`Fields.${field}`)}</span>
      </Col>
      <Col className="p-0">
        <span className="">{t('RequestDetailsPage.requestNumber')}</span>
        <span className="pr-3">{requestPrettyId}</span>
      </Col>
    </Row>
  );
}
