import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import React from 'react';
import ls from 'local-storage';
import * as jwt from 'jsonwebtoken';
import ActiveRequests from '../../containers/ActiveRequests';
import ActiveRequestsWithoutLoginComponent from '../ActiveRequestsWithoutLogin';
import PostponedRequests from '../../containers/PostponedRequests';
import HistoryRequests from '../../containers/HistoryRequests';
import Footer from '../_shared/Footer';

import './Requests.css';
import HeaderComponent from '../_shared/Header';

export default function RequestsComponent(props) {
  const { t } = useTranslation('common');
  const { history } = props;
  const token = ls.get('token');
  const validToken =
    token && jwt.decode(token, { complete: true }).payload.exp > Math.round(Date.now() / 1000);

  return (
    <flex>
      <div className="outer">
        <div className="two">
          <HeaderComponent history={history} />
          <Tabs
            className="tabs d-flex text-center"
            defaultActiveKey={t('RequestsPage.tabs.active')}
            id="requests-tabs"
          >
            <Tab
              tabClassName="flex-fill tab-color"
              eventKey={t('RequestsPage.tabs.active')}
              title={t('RequestsPage.tabs.active')}
            >
              {validToken && <ActiveRequests history={history} />}
              {!validToken && <ActiveRequestsWithoutLoginComponent history={history} />}
            </Tab>
            <Tab
              tabClassName="flex-fill tab-color"
              eventKey={t('RequestsPage.tabs.postponed')}
              title={t('RequestsPage.tabs.postponed')}
            >
              <PostponedRequests />
            </Tab>
            <Tab
              tabClassName="flex-fill tab-color"
              eventKey={t('RequestsPage.tabs.history')}
              title={t('RequestsPage.tabs.history')}
            >
              <HistoryRequests />
            </Tab>
          </Tabs>
        </div>
        <div className="three">
          <Footer url="requests" />
        </div>
      </div>
    </flex>
  );
}
