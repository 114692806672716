import { appliedWorkersConstants } from '../../constants';

const initialState = {
  acceptWorker: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export function appliedWorkers(state = initialState, action) {
  switch (action.type) {
    case appliedWorkersConstants.ACCEPT_WORKER_REQUEST:
      return {
        ...state,
        acceptWorker: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case appliedWorkersConstants.ACCEPT_WORKER_SUCCESS:
      return {
        ...state,
        acceptWorker: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case appliedWorkersConstants.ACCEPT_WORKER_FAILURE:
      return {
        ...state,
        acceptWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: { ar: action.error.message, en: action.error.messageEn },
          },
        },
      };

    case appliedWorkersConstants.CLEAR:
      return initialState;
    default:
      return state;
  }
}
