import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { requestsActions } from '../../store/actions';
import ActiveRequestsComponent from '../../components/ActiveRequests';

class ActiveRequests extends Component {
  componentDidMount() {
    const { getAllCustomerRequests } = this.props;

    getAllCustomerRequests('active,pending');
  }

  cardClicked = (requestPrettyId, requestStatus) => {
    const { history } = this.props;

    if (requestStatus === 'pending') {
      return history.push(`/choose-worker/${requestPrettyId}`);
    }

    return history.push(`/request-details/${requestPrettyId}`);
  };

  render() {
    const {
      activeRequestsList,
      isFetchingGetActiveRequestsList,
      isErrorGetActiveRequestsList,
      getActiveRequestsListErrorMessage,
    } = this.props;

    return (
      <Col className="p-0">
        <ActiveRequestsComponent
          requests={activeRequestsList}
          cardClicked={this.cardClicked}
          isFetchingGetActiveRequestsList={isFetchingGetActiveRequestsList}
          isErrorGetActiveRequestsList={isErrorGetActiveRequestsList}
          getActiveRequestsListErrorMessage={getActiveRequestsListErrorMessage}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  activeRequestsList: state.requests.active,
  isFetchingGetActiveRequestsList: state.requests.getActiveRequests.isFetching,
  isSuccessGetActiveRequestsList: state.requests.getActiveRequests.isSuccess,
  isErrorGetActiveRequestsList: state.requests.getActiveRequests.isFail.isError,
  getActiveRequestsListErrorMessage: state.requests.getActiveRequests.isFail.message,
});

const actionCreators = {
  getAllCustomerRequests: requestsActions.getAllCustomerRequests,
};

export default connect(mapStateToProps, actionCreators)(ActiveRequests);
