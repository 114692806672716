import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import validator from 'validator';
import { loginUser, checkIfAccountExist } from '../../store/actions';
import OtpComponent from '../../components/Otp';

class OtpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: { value: '', isValid: true, message: { ar: '', message: '' } },
      time: 120,
    };
    const { history, username } = this.props;

    if (!username) {
      return history.push('/login');
    }

    this.timer = this.startTimer();
  }

  onPasswordFieldChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      },
    });
  };

  formIsValid() {
    const { password } = this.state;
    let isFormValid = true;

    if (!validator.isByteLength(password.value, { min: 4, max: 4 })) {
      isFormValid = false;
      password.isValid = false;
      password.message = {
        ar: 'برجاء ادخال رقم االتامينى صحيحا',
        en: 'Please, Enter The Security Code Correct',
      };
    }

    if (!isFormValid) {
      this.setState({ password });
    }

    return isFormValid;
  }

  resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(this.state));

    /*
    loop through each item in state and if it's safe to assume that only
    form values have an 'isValid' property, we can use that to reset their
    validation states and keep their existing value property. This process
    makes it easy to set all validation states on form inputs in case the number
    of fields on our form grows in the future.
    */
    Object.keys(state).map((key) => {
      if (state[key].hasOwnProperty('isValid')) {
        state[key].isValid = true;
        state[key].message = { ar: '', en: '' };
      }
    });

    return state;
  };

  handleSubmitLoginForm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { password } = this.state;
    const { history, username, loginUser: loginUserAction } = this.props;

    // reset states before the validation procedure is run.
    const restValidationState = this.resetValidationStates();
    console.log('restValidationState', restValidationState);
    this.setState(restValidationState, () => {
      if (this.formIsValid()) {
        loginUserAction({ username, password: password.value }, history);
      }
    });
  };

  startTimer() {
    const { username, history, checkIfAccountExist: checkIfAccountExistAction } = this.props;

    return setInterval(() => {
      const { time } = this.state;
      if (time === 0) {
        checkIfAccountExistAction(username, history);
        this.stopTimer();
        return this.setState({ time: 120 }, () => {
          this.timer = this.startTimer();
        });
      }
      return this.setState((prevState) => ({ time: prevState.time - 1 }));
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  render() {
    const { password, validated, time } = this.state;
    const {
      isFetchingLogin,
      isSuccessLogin,
      isErrorLogin,
      loginErrorMessage,
      isErrorCheckAccountExistBefore,
      checkAccountExistBeforeErrorMessage,
    } = this.props;

    return (
      <>
        <Col className="backgroundImage text-white d-flex align-items-center justify-content-center text-center">
          <OtpComponent
            validated={validated}
            time={time}
            password={password}
            onPasswordFieldChange={this.onPasswordFieldChange}
            handleSubmitLoginForm={this.handleSubmitLoginForm}
            isFetchingLogin={isFetchingLogin}
            isSuccessLogin={isSuccessLogin}
            isErrorLogin={isErrorLogin}
            loginErrorMessage={loginErrorMessage}
            isErrorCheckAccountExistBefore={isErrorCheckAccountExistBefore}
            checkAccountExistBeforeErrorMessage={checkAccountExistBeforeErrorMessage}
          />
        </Col>
      </>
    );
  }
}

OtpContainer.displayName = 'OtpContainer';

const mapStateToProps = (state) => ({
  username: state.login.username,
  isFetchingLogin: state.otp.login.isFetching,
  isSuccessLogin: state.otp.login.isSuccess,
  isErrorLogin: state.otp.login.isFail.isError,
  loginErrorMessage: state.otp.login.isFail.message,
  isErrorCheckAccountExistBefore: state.login.checkAccountExistBefore.isFail.isError,
  checkAccountExistBeforeErrorMessage: state.login.checkAccountExistBefore.isFail.message,
});

const actionCreators = {
  loginUser,
  checkIfAccountExist,
};

export default connect(mapStateToProps, actionCreators)(OtpContainer);
