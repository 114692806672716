import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function StcPayInAppPaymentFailedContainer() {
  const { t } = useTranslation('common');

  return (
    <Col className="backgroundImage text-white d-flex align-items-center justify-content-center text-center">
      <Row>
        <Col xs={12} md={12}>
          <span>{t('stcPayInAppPaymentFailedPage.text')}</span>
        </Col>
        <Col xs={12} md={12}>
          <Link to="/requests">{t('stcPayInAppPaymentFailedPage.goToYourRequests')}</Link>
        </Col>
      </Row>
    </Col>
  );
}

export default StcPayInAppPaymentFailedContainer;
