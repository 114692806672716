import React from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

import './PromoCodeModalComponent.css';

export default function PromoCodeModalComponent(props) {
  const {
    promoCode,
    showPromoCodeModal,
    isFetchingValidatePromoCode,
    foundPromoCode,
    togglePromoCodeModal,
    onPromoCodeFieldChange,
    // onClickPromoCodeButton,
    isErrorPromoCode,
    promoCodeErrorMessage,
  } = props;
  const { t, i18n } = useTranslation('common');
  return (
    <Modal
      className="promoCode-modal"
      show={showPromoCodeModal}
      onHide={togglePromoCodeModal}
      dialogClassName="promo-code-modal-dialog"
    >
      <Modal.Title className="text-center mt-2 mb-2">
        {t('servicesPage.promoCode.discountInputPlaceholderText')}
      </Modal.Title>
      <Modal.Body>
        {foundPromoCode && (
          <>
            <Row>
              <Col>
                <span>{foundPromoCode.message[i18n.language]}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" md="auto">
                {t('servicesPage.promoCode.expireAtLabelText')}
              </Col>
              <Col>
                <span>{moment(foundPromoCode.expireAt).format('YYYY-MM-DD')}</span>
              </Col>
            </Row>
            <Row>
              <Col className="pt-2">
                <Button
                  variant="primary"
                  className="mb-2 btn-block"
                  type="submit"
                  onClick={() => togglePromoCodeModal()}
                >
                  {t('servicesPage.promoCode.closePromoCodeModalButtonText')}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {!foundPromoCode && (
          <>
            <FormControl
              className="mb-3"
              type="text"
              name="promoCode"
              value={promoCode}
              onChange={onPromoCodeFieldChange}
              placeholder={t('servicesPage.promoCode.discountInputPlaceholderText')}
              autoComplete="off"
              readOnly={isFetchingValidatePromoCode}
            />

            {/* <Col xs={5} md={5}> */}
            {/*  <Button */}
            {/*    variant="primary" */}
            {/*    className="mb-2 btn-block" */}
            {/*    type="submit" */}
            {/*    disabled={isFetchingValidatePromoCode} */}
            {/*    onClick={onClickPromoCodeButton} */}
            {/*  > */}
            {/*    {t('servicesPage.promoCode.applyPromoCodeButtonText')} */}
            {/*  </Button> */}
            {/* </Col> */}

            {isErrorPromoCode && (
              <span className="text-danger">{promoCodeErrorMessage[i18n.language]}</span>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
