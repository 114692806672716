import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import * as jwt from 'jsonwebtoken';
import ls from 'local-storage';
import i18next from 'i18next';
import ServicesContainer from '../Services';
import LoginContainer from '../Login';
import OtpContainer from '../Otp';
import ChooseWorkerFromAppliedContainer from '../ChooseWorkerFromApplied';
import RequestsContainer from '../Requests';
import OthersContainer from '../Others';
import RequestDetailsContainer from '../RequestDetails';
import SignUpContainer from '../SignUp';
import { WorkerDetailsContainer } from '../WorkerDetails';
import ErrorBoundary from '../ErrorBoundary';
import RateWorkerContainer from '../RateWorker';
import AboutUs from '../../components/AboutUs';
import StcPayInAppPaymentSuccess from '../StcPayInAppPaymentSuccess';
import StcPayInAppPaymentFailed from '../StcPayInAppPaymentFailed';

import './App.css';

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      direction: 'ar',
    };

    i18next.on('languageChanged', () => {
      this.setState({ direction: i18next.dir(i18next.language) });
    });
  }

  componentDidMount() {
    const { history } = this.props;
    const token = ls.get('token');

    if (token) {
      const decodeToken = jwt.decode(token, { complete: true });
      if (decodeToken.payload.exp < Math.round(Date.now() / 1000)) {
        // Invalid Token
        ls.clear();
        history.push('/login');
      }
    }
  }

  render() {
    const token = ls.get('token');
    const { direction } = this.state;

    return (
      <ErrorBoundary>
        <Container className="h-100" fluid style={{ direction }}>
          {i18next.language === 'ar' && (
            <link
              rel="stylesheet"
              type="text/css"
              href={`${process.env.PUBLIC_URL}/css/bootstrap-rtl.css`}
            />
          )}
          <Row className="h-100">
            <Switch>
              <Route exact path="/" component={ServicesContainer} />
              <Route exact path="/requests" component={RequestsContainer} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/others" component={OthersContainer} />
              <Route exact path="/login" component={LoginContainer} />
              <Route exact path="/otp" component={OtpContainer} />
              <Route exact path="/signUp" component={SignUpContainer} />
              {token && (
                <Switch>
                  <Route
                    exact
                    path="/choose-worker/:id"
                    component={ChooseWorkerFromAppliedContainer}
                  />
                  <Route exact path="/worker-details/:id" component={WorkerDetailsContainer} />
                  <Route exact path="/request-details/:id" component={RequestDetailsContainer} />
                  <Route exact path="/request-details/:id/rate" component={RateWorkerContainer} />
                  <Route
                    exact
                    path="/stc-in-app-payment-success"
                    component={StcPayInAppPaymentSuccess}
                  />
                  <Route
                    exact
                    path="/stc-in-app-payment-failed"
                    component={StcPayInAppPaymentFailed}
                  />
                </Switch>
              )}
            </Switch>
          </Row>
        </Container>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = () => ({});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(AppContainer);
