export const servicesConstants = {
  GET_ALL_CONFIGS: 'GET_ALL_CONFIGS',
  GET_ALL_CONFIGS_SUCCESS: 'GET_ALL_CONFIGS_SUCCESS',
  GET_ALL_CONFIGS_FAIL: 'GET_ALL_CONFIGS_FAIL',

  ADD_NEW_REQUEST: 'ADD_NEW_REQUEST',
  ADD_NEW_REQUEST_SUCCESS: 'ADD_NEW_REQUEST_SUCCESS',
  ADD_NEW_REQUEST_FAILED: 'ADD_NEW_REQUEST_FAILED',

  USE_PROMO_CODE: 'USE_PROMO_CODE',
  USE_PROMO_CODE_SUCCESS: 'USE_PROMO_CODE_SUCCESS',
  USE_PROMO_CODE_FAILED: 'USE_PROMO_CODE_FAILED',

  GET_CUSTOMER_ADS: 'GET_CUSTOMER_ADS',
  GET_CUSTOMER_ADS_SUCCESS: 'GET_CUSTOMER_ADS_SUCCESS',
  GET_CUSTOMER_ADS_FAILED: 'GET_CUSTOMER_ADS_FAILED',

  CLEAR: 'CLEAR',
};
