import React from 'react';
import { Modal, Button, Col, Form } from 'react-bootstrap';

import './ReconditioningServiceRequirementsModal.css';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

export default function ReconditioningServiceRequirementsModalComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    confirmOrderNewRequest,
    onClickOrderNewRequestButton,
    show,
    isFetchingOrderNewService,
    isErrorOrderNewService,
    orderNewServiceErrorMessage,
    field,
    fieldRequirements,
    handleInputChange,
  } = props;

  return (
    <Modal
      show={show}
      keyboard={false}
      size="sm"
      onHide={() => {
        onClickOrderNewRequestButton();
      }}
      dialogClassName="modal-confirm modal-dialog-centered"
      centered
    >
      <Modal.Body className="p-0">
        <h5>{t(`Fields.${field}`)}</h5>
        <Form.Control
          name="fieldRequirements"
          value={fieldRequirements}
          onChange={handleInputChange}
          as="textarea"
          rows="3"
        />
        {isErrorOrderNewService && (
          <span className="text-danger">{orderNewServiceErrorMessage[i18n.language]}</span>
        )}
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Col xs={12} md={12} className="p-0">
          <Button
            variant="secondary"
            className="btn-block"
            disabled={isFetchingOrderNewService}
            onClick={() => {
              confirmOrderNewRequest();
            }}
          >
            {isFetchingOrderNewService && (
              <Spinner
                aria-hidden="true"
                size="sm"
                role="status"
                animation="border"
                variant="primary"
              />
            )}
            {t('servicesPage.confirmModal.agreeToOrderButtonText')}
          </Button>
        </Col>
        <Col xs={12} md={12} className="p-0">
          <Button
            variant="danger"
            className="btn-block"
            disabled={isFetchingOrderNewService}
            onClick={() => {
              onClickOrderNewRequestButton();
            }}
          >
            {t('servicesPage.confirmModal.cancellationButtonText')}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
