import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import LoadingComponent from '../../components/_shared/Loading';
import RequestDetailsComponent from '../../components/RequestDetails';
import { usersActions, requestsActions } from '../../store/actions';

import './RequestDetails.css';

class RequestDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPostponeModal: false,
      showCancellationModal: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id: requestPrettyId },
      },
      getRequestDetailsByPrettyId,
      getRequestDetailsByPrettyIdFromFirebase,
    } = this.props;

    getRequestDetailsByPrettyId(requestPrettyId);
    getRequestDetailsByPrettyIdFromFirebase(requestPrettyId);
  }

  componentDidUpdate(prevProps) {
    const { isSuccessCreateOrderForTheInvoiceInStcPay, stcPayPaymentRef } = this.props;
    if (
      !_.isEqual(
        _.get(prevProps, 'requestDetailsFromFirebase.workerRequestToPostponeTheRequest.reason'),
        _.get(this.props, 'requestDetailsFromFirebase.workerRequestToPostponeTheRequest.reason'),
      )
    ) {
      this.togglePostponeModal();
    }

    if (isSuccessCreateOrderForTheInvoiceInStcPay && stcPayPaymentRef) {
      // calls the JS SDK to pass the “Payment Ref”
      window.STCPAY.paymentRequest(stcPayPaymentRef);
    }
  }

  togglePostponeModal = () => {
    this.setState((prevState) => ({ showPostponeModal: !prevState.showPostponeModal }));
  };

  handelAddWorkerToFavoriteClick = (id) => {
    const { addWorkerToFavorite } = this.props;

    addWorkerToFavorite(id);
  };

  insuranceCalc = (createdDate) => {
    const now = moment();
    const created = moment(createdDate);
    const dif = now.diff(created, 'days');
    return dif > 30 ? 0 : 30 - dif;
  };

  toggleCancellationModal = () => {
    this.setState((prevState) => ({ showCancellationModal: !prevState.showCancellationModal }));
  };

  render() {
    const { showPostponeModal, showCancellationModal } = this.state;
    const {
      requestDetailsFromFirebase,
      requestDetails,
      history,
      isFetchingAddWorkerToFavorites,
      isErrorAddWorkerToFavorites,
      addWorkerToFavoritesErrorMessage,
      isErrorConfirmThePostponeRequest,
      confirmThePostponeRequestErrorMessage,
      confirmThePostponeRequest,
      isFetchingGetRequestDetails,
      isErrorGetRequestDetails,
      getRequestDetailsErrorMessage,
      rejectTheInvoice,
      isFetchingRejectTheInvoice,
      isErrorRejectTheInvoice,
      errorMessageRejectTheInvoice,
      isFetchingCreateOrderForTheInvoiceInStcPay,
      isErrorCreateOrderForTheInvoiceInStcPay,
      errorMessageCreateOrderForTheInvoiceInStcPay,
      createOrderForTheInvoiceInStcPay,
    } = this.props;

    if (isFetchingGetRequestDetails) {
      return <LoadingComponent />;
    }

    return (
      <RequestDetailsComponent
        request={requestDetails}
        requestFromFirebase={requestDetailsFromFirebase}
        insuranceCalc={this.insuranceCalc}
        addWorkerToFavorite={this.handelAddWorkerToFavoriteClick}
        showPostponeModal={showPostponeModal}
        showCancellationModal={showCancellationModal}
        toggleCancellationModal={this.toggleCancellationModal}
        history={history}
        isFetchingAddWorkerToFavorites={isFetchingAddWorkerToFavorites}
        isErrorAddWorkerToFavorites={isErrorAddWorkerToFavorites}
        addWorkerToFavoritesErrorMessage={addWorkerToFavoritesErrorMessage}
        togglePostponeModal={this.togglePostponeModal}
        isErrorConfirmThePostponeRequest={isErrorConfirmThePostponeRequest}
        confirmThePostponeRequestErrorMessage={confirmThePostponeRequestErrorMessage}
        confirmThePostponeRequest={confirmThePostponeRequest}
        isErrorGetRequestDetails={isErrorGetRequestDetails}
        getRequestDetailsErrorMessage={getRequestDetailsErrorMessage}
        rejectTheInvoice={rejectTheInvoice}
        isFetchingRejectTheInvoice={isFetchingRejectTheInvoice}
        isErrorRejectTheInvoice={isErrorRejectTheInvoice}
        errorMessageRejectTheInvoice={errorMessageRejectTheInvoice}
        isFetchingCreateOrderForTheInvoiceInStcPay={isFetchingCreateOrderForTheInvoiceInStcPay}
        isErrorCreateOrderForTheInvoiceInStcPay={isErrorCreateOrderForTheInvoiceInStcPay}
        errorMessageCreateOrderForTheInvoiceInStcPay={errorMessageCreateOrderForTheInvoiceInStcPay}
        createOrderForTheInvoiceInStcPay={createOrderForTheInvoiceInStcPay}
      />
    );
  }
}

RequestDetailsContainer.displayName = 'RequestDetailsContainer';

function mapStateToProps(state) {
  const { requests, users } = state;

  return {
    requests,
    users,
    requestDetailsFromFirebase: state.requests.detailsFromFirebase,

    isFetchingGetRequestDetails: state.requests.getRequestDetails.isFetching,
    isErrorGetRequestDetails: state.requests.getRequestDetails.isFail.isError,
    getRequestDetailsErrorMessage: state.requests.getRequestDetails.isFail.message,
    requestDetails: state.requests.details,

    isFetchingAddWorkerToFavorites: state.users.addWorkerToFavorite.isFetching,
    isErrorAddWorkerToFavorites: state.users.addWorkerToFavorite.isFail.isError,
    addWorkerToFavoritesErrorMessage: state.users.addWorkerToFavorite.isFail.message,

    isErrorConfirmThePostponeRequest: state.requests.confirmThePostponeRequest.isFail.isError,
    confirmThePostponeRequestErrorMessage: state.requests.confirmThePostponeRequest.isFail.message,

    isFetchingRejectTheInvoice: state.requests.rejectTheInvoice.isFetching,
    isErrorRejectTheInvoice: state.requests.rejectTheInvoice.isFail.isError,
    errorMessageRejectTheInvoice: state.requests.rejectTheInvoice.isFail.message,

    isFetchingCreateOrderForTheInvoiceInStcPay:
      state.requests.createOrderForTheInvoiceInStcPay.isFetching,
    isSuccessCreateOrderForTheInvoiceInStcPay:
      state.requests.createOrderForTheInvoiceInStcPay.isSuccess,
    isErrorCreateOrderForTheInvoiceInStcPay:
      state.requests.createOrderForTheInvoiceInStcPay.isFail.isError,
    errorMessageCreateOrderForTheInvoiceInStcPay:
      state.requests.createOrderForTheInvoiceInStcPay.isFail.message,
    stcPayPaymentRef: state.requests.stcPayPaymentRef,
  };
}

const actionCreators = {
  getRequestDetailsByPrettyId: requestsActions.getRequestDetailsByPrettyId,
  getRequestDetailsByPrettyIdFromFirebase: requestsActions.getRequestDetailsByPrettyIdFromFirebase,
  addWorkerToFavorite: usersActions.addWorkerToFavorite,
  rateWorker: usersActions.rateWorker,
  confirmThePostponeRequest: requestsActions.confirmTheWorkerRequestToPostponeTheRequest,
  createOrderForTheInvoiceInStcPay: requestsActions.createOrderForTheInvoiceInStcPay,
  rejectTheInvoice: requestsActions.rejectTheInvoice,
};

export default connect(mapStateToProps, actionCreators)(RequestDetailsContainer);
