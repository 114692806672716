import { requestsConstants, servicesConstants } from '../../constants';

const initialState = {
  active: [],
  postponed: [],
  history: [],
  details: {},
  detailsFromFirebase: {
    workerRequestToPostponeTheRequest: {},
    appliedWorkers: [],
    paymentStatuses: [],
    workerWage: 0,
  },
  stcPayPaymentRef: undefined,
  cancelActiveRequest: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  cancelRequest: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  confirmThePostponeRequest: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getRequestDetailsFromFirebase: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getRequestDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getActiveRequests: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  rejectTheInvoice: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  createOrderForTheInvoiceInStcPay: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  confirmOrderForTheInvoiceInStcPay: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export function requests(state = initialState, action) {
  switch (action.type) {
    case requestsConstants.GET_ALL_ACTIVE_REQUESTS_REQUEST:
      return {
        ...state,
        getActiveRequests: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_ALL_ACTIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        active: action.requests,
        getActiveRequests: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_ALL_ACTIVE_REQUESTS_FAILURE:
      return {
        ...state,
        getActiveRequests: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case requestsConstants.GET_REQUEST_DETAILS_REQUEST:
      return {
        ...state,
        getRequestDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.request,
        getRequestDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_REQUEST_DETAILS_FAILURE:
      return {
        ...state,
        getRequestDetails: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_REQUEST:
      return {
        ...state,
        getRequestDetailsFromFirebase: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_SUCCESS:
      return {
        ...state,
        detailsFromFirebase: action.request || {},
        getRequestDetailsFromFirebase: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.GET_REQUEST_DETAILS_REQUEST_FROM_FIREBASE_FAILURE:
      return {
        ...state,
        getRequestDetailsFromFirebase: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case requestsConstants.CANCEL_REQUEST_REQUEST:
      return {
        ...state,
        cancelRequest: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        cancelRequest: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case requestsConstants.CANCEL_REQUEST_FAILURE:
      return {
        ...state,
        cancelRequest: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case requestsConstants.CANCEL_ACTIVE_REQUEST_REQUEST: {
      return {
        ...state,
        cancelActiveRequest: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CANCEL_ACTIVE_REQUEST_SUCCESS: {
      return {
        ...state,
        cancelActiveRequest: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CANCEL_ACTIVE_REQUEST_FAILURE: {
      return {
        ...state,
        cancelActiveRequest: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };
    }

    case requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_REQUEST: {
      return {
        ...state,
        confirmThePostponeRequest: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_SUCCESS: {
      return {
        ...state,
        confirmThePostponeRequest: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CONFIRM_THE_WORKER_REQUEST_TO_POSTPONE_THE_REQUEST_FAILURE: {
      return {
        ...state,
        confirmThePostponeRequest: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };
    }

    case requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_REQUEST: {
      return {
        ...state,
        createOrderForTheInvoiceInStcPay: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_SUCCESS: {
      return {
        ...state,
        stcPayPaymentRef: action.stcPayPaymentRef,
        createOrderForTheInvoiceInStcPay: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CREATE_ORDER_FOR_THE_INVOICE_IN_STC_PAY_FAILURE: {
      return {
        ...state,
        createOrderForTheInvoiceInStcPay: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };
    }

    case requestsConstants.CONFIRM_ORDER_IN_STC_PAY_REQUEST: {
      return {
        ...state,
        confirmOrderForTheInvoiceInStcPay: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CONFIRM_ORDER_IN_STC_PAY_SUCCESS: {
      return {
        ...state,
        confirmOrderForTheInvoiceInStcPay: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.CONFIRM_ORDER_IN_STC_PAY_FAILURE: {
      return {
        ...state,
        confirmOrderForTheInvoiceInStcPay: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };
    }

    case requestsConstants.REJECT_THE_INVOICE_REQUEST: {
      return {
        ...state,
        rejectTheInvoice: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.REJECT_THE_INVOICE_SUCCESS: {
      return {
        ...state,
        rejectTheInvoice: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case requestsConstants.REJECT_THE_INVOICE_FAILURE: {
      return {
        ...state,
        rejectTheInvoice: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };
    }

    case servicesConstants.ADD_NEW_REQUEST_SUCCESS: {
      return {
        ...state,
        details: action.request,
      };
    }

    case requestsConstants.CLEAR:
      return initialState;

    default:
      return state;
  }
}
