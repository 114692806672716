import { Api, Urls, Status } from '../../services/api';
import { loginConstants } from '../../constants';

const checkAccountExist = (username, historyPush) => ({
  type: loginConstants.ACCOUNT_EXIST_BEFORE,
  username,
  historyPush,
});

const checkAccountExistSuccess = () => ({
  type: loginConstants.ACCOUNT_EXIST_BEFORE_SUCCESS,
});

const checkAccountExistFail = (err) => ({
  type: loginConstants.ACCOUNT_EXIST_BEFORE_FAIL,
  err,
});

export const checkIfAccountExist = function (username, historyPush) {
  return async (dispatch) => {
    dispatch(checkAccountExist(username, historyPush));
    const { user } = Urls;
    const url = user.checkAccountExist;
    const api = new Api();

    const response = await api.post(url, { username });

    if (Status.isSuccess(response.status)) {
      dispatch(checkAccountExistSuccess());
      return historyPush.push('/otp');
    }

    if (Status.isNotFound(response.status)) {
      dispatch(checkAccountExistSuccess());
      return historyPush.push('/signUp');
    }

    const { response: message } = response;

    return dispatch(checkAccountExistFail(message));
  };
};
