import React from 'react';
import { Col, Form, Modal, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import './CancelRequestModal.css';

export default function OtherReasonModal(props) {
  const {
    showOtherReasonModal,
    toggleTheCancellationReasonModal,
    cancellationReasonComment,
    handelReasonCommentChange,
    configs: {
      enums: { cancellingActiveRequestReasonsAsArray = [] },
    },
    cancelTheRequestAction,
    isErrorCancelActiveRequest,
    cancelActiveRequestErrorMessage,
  } = props;
  const { t, i18n } = useTranslation('common');
  const cancellationReason = _.find(cancellingActiveRequestReasonsAsArray, (cancellationReason) => {
    return cancellationReason.code === 'other';
  });

  return (
    <Modal
      show={showOtherReasonModal}
      onHide={() => toggleTheCancellationReasonModal('showOtherReasonModal')}
      centered
    >
      <Modal.Header className="cancellation-reason-modal-header" closeButton={false}>
        <Modal.Title>{cancellationReason.messages[i18n.language]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form>
              <Form.Control
                value={cancellationReasonComment}
                onChange={handelReasonCommentChange}
                as="textarea"
                rows="3"
              />
            </Form>
          </Col>
        </Row>
        <Col xs={12} md={12}>
          {isErrorCancelActiveRequest && (
            <span className="text-danger">{cancelActiveRequestErrorMessage[i18n.language]}</span>
          )}
        </Col>
      </Modal.Body>
      <Modal.Footer className="cancellation-reason-modal-footer">
        <Button
          variant="secondary"
          onClick={() => toggleTheCancellationReasonModal('showOtherReasonModal')}
        >
          {t('ChooseWorkerPage.cancellationReasonModal.cancelButtonText')}
        </Button>
        <Button variant="danger" onClick={() => cancelTheRequestAction()}>
          {t('ChooseWorkerPage.cancellationReasonModal.confirmButtonText')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
