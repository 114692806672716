import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import './CancelRequestModal.css';

export default function TechnicianNotSuitableReasonModal(props) {
  const {
    showTechnicianNotSuitableReasonModal,
    toggleTheCancellationReasonModal,
    configs: {
      enums: { cancellingActiveRequestReasonsAsArray = [] },
    },
    cancelTheRequestAction,
    isErrorCancelActiveRequest,
    cancelActiveRequestErrorMessage,
  } = props;
  const { t, i18n } = useTranslation('common');
  const cancellationReason = _.find(cancellingActiveRequestReasonsAsArray, (cancellationReason) => {
    return cancellationReason.code === 'technicianNotSuitable';
  });

  return (
    <Modal
      show={showTechnicianNotSuitableReasonModal}
      onHide={() => toggleTheCancellationReasonModal('showTechnicianNotSuitableReasonModal')}
      centered
    >
      <Modal.Header className="cancellation-reason-modal-header" closeButton={false}>
        <Modal.Title>{cancellationReason.messages[i18n.language]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="pb-3" xs={12} md={12}>
            <div
              onClick={() =>
                toggleTheCancellationReasonModal('showTechnicianNotSuitableReasonModal')
              }
            >
              <span>{t('RequestDetailsPage.cancellationReasons.requestAnotherTechnician')}</span>
            </div>
          </Col>

          <Col className="pb-3" xs={12} md={12}>
            <div onClick={() => cancelTheRequestAction()}>
              <span>{t('RequestDetailsPage.cancellationReasons.confirmation')}</span>
            </div>
          </Col>

          <Col className="pb-3" xs={12} md={12}>
            <span onClick={() => (window.location = 'https://fanni.sa/price-list/')}>
              {t('RequestDetailsPage.cancellationReasons.pricingList')}
            </span>
          </Col>
        </Row>
        <Col xs={12} md={12}>
          {isErrorCancelActiveRequest && (
            <span className="text-danger">{cancelActiveRequestErrorMessage[i18n.language]}</span>
          )}
        </Col>
      </Modal.Body>
    </Modal>
  );
}
