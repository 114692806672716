import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import { Cities } from '../../locales/en/common.json';

import './SignUp.css';
import HeaderComponent from '../_shared/Header';

export default function SignUpComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    name,
    email,
    birthday,
    city,
    gender,
    termsAndConditions,
    isFetchingSignUp,
    isErrorSignUp,
    signUpErrorMessage,
    onChangeInputValue,
    onChangeCheckbox,
    handleSubmitSignUpForm,
    history,
  } = props;
  const citiesName = Object.keys(Cities);

  const cityOptions = citiesName.map((city) => (
    <option key={city} value={city}>
      {t(`Cities.${city}`)}
    </option>
  ));

  const nameGroupClass = classNames('form-group', { 'has-error': !name.isValid });
  const emailGroupClass = classNames('form-group', { 'has-error': !email.isValid });
  const birthdayGroupClass = classNames('form-group', { 'has-error': !birthday.isValid });
  const cityGroupClass = classNames('form-group', { 'has-error': !city.isValid });
  const genderGroupClass = classNames('form-group', { 'has-error': !gender.isValid });
  const termsAndConditionsGroupClass = classNames('form-group', {
    'has-error': !termsAndConditions.isValid,
  });

  return (
    <>
      <HeaderComponent history={history} isUseBackgroundImage />
      <Col className="backgroundImage text-white d-flex align-items-center justify-content-center text-center">
        <Form noValidate>
          <Row>
            <Col xs={12} md={12} className="mt-2">
              <Form.Group className={nameGroupClass}>
                <Form.Label>{t('SignUpPage.name')}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name.value}
                  onChange={(event) => onChangeInputValue(event)}
                  placeholder={t('SignUpPage.name')}
                />
                <span className="text-danger">{name.message[i18n.language]}</span>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group className={emailGroupClass}>
                <Form.Label>{t('SignUpPage.email')}</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email.value}
                  onChange={(event) => onChangeInputValue(event)}
                  placeholder={t('SignUpPage.email')}
                />
                <span className="text-danger">{email.message[i18n.language]}</span>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group className={birthdayGroupClass}>
                <Form.Label>{t('SignUpPage.birthDay')}</Form.Label>
                <Form.Control
                  type="Date"
                  name="birthday"
                  value={birthday.value}
                  onChange={(event) => onChangeInputValue(event)}
                  placeholder={t('SignUpPage.birthDay')}
                />
                <span className="text-danger">{birthday.message[i18n.language]}</span>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group className={cityGroupClass}>
                <Form.Label htmlFor="inlineFormCustomSelect">{t('SignUpPage.city')}</Form.Label>
                <Form.Control
                  as="select"
                  name="city"
                  value={city.value}
                  onChange={(event) => onChangeInputValue(event)}
                  className="mr-sm-2"
                  id="inlineFormCustomSelect"
                  custom
                >
                  <option value="">{t('SignUpPage.cityDefaultOption')}</option>
                  {cityOptions}
                </Form.Control>
                <span className="text-danger">{city.message[i18n.language]}</span>
              </Form.Group>
            </Col>
            <Col xs={12} md={12} className="pt-3">
              <Form.Group className={genderGroupClass}>
                <Row>
                  <Col xs={2} md={2}>
                    <Form.Label>{t('SignUpPage.gender')}</Form.Label>
                  </Col>
                  <Col xs={4} md={4}>
                    <Form.Check
                      type="radio"
                      label={t('SignUpPage.male')}
                      name="gender"
                      value="male"
                      checked={gender.value === 'male'}
                      onChange={(event) => onChangeInputValue(event)}
                      required
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <Form.Check
                      type="radio"
                      label={t('SignUpPage.female')}
                      name="gender"
                      value="female"
                      checked={gender.value === 'female'}
                      onChange={(event) => onChangeInputValue(event)}
                      required
                    />
                  </Col>
                </Row>
                <span className="text-danger">{gender.message[i18n.language]}</span>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <div className={termsAndConditionsGroupClass}>
                <input
                  type="checkbox"
                  name="termsAndConditions"
                  className="form-check-input"
                  checked={termsAndConditions.value}
                  onChange={(event) => onChangeCheckbox(event)}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="exampleCheck1"
                  onClick={() => {
                    window.location = 'https://fanni.sa/customer-terms.html';
                  }}
                >
                  {t('SignUpPage.termsAndConditions')}
                </label>
                <span className="text-danger">{termsAndConditions.message[i18n.language]}</span>
              </div>
            </Col>
            <Col xs={12} md={12}>
              {isErrorSignUp && <Alert variant="danger">{signUpErrorMessage[i18n.language]}</Alert>}
            </Col>
            <Col>
              <Button
                variant="primary"
                className="mb-2"
                type="submit"
                disabled={isFetchingSignUp}
                onClick={!isFetchingSignUp && termsAndConditions ? handleSubmitSignUpForm : null}
              >
                {isFetchingSignUp
                  ? t('SignUpPage.SignUpLoadingButtonText')
                  : t('SignUpPage.SignUpButtonText')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  );
}
