import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'react-bootstrap';
import React from 'react';

import './RateWorker.css';
import HeaderComponent from '../_shared/Header';

export default function RateWorkerTranslationComponent(props) {
  const {
    rating,
    reasons,
    ratingComment,
    handelReasonSelect,
    handelRatingCommentChange,
    handleRatingChange,
    handleSubmit,
    history,
  } = props;
  const { t } = useTranslation('common');

  const Star = ({ selected = false, onClick = (f) => f }) => (
    <div className={selected ? 'star selected' : 'star'} onClick={onClick} />
  );

  const RenderRatingStars = () => {
    return (
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((n, i) => (
          <Star key={i} selected={i < rating} onClick={() => handleRatingChange(i + 1)} />
        ))}
        <p>{rating} of 5 stars</p>
      </div>
    );
  };

  const RenderReasons = () => {
    let reasonsArr = reasons.good;
    if (rating <= 3) reasonsArr = reasons.bad;

    return reasonsArr.map((reason) => (
      <div key={`inline-radio-${reason}`} className="mb-3">
        <Form.Check
          inline
          label={t(`ChooseWorkerPage.ratingReasons.${reason}`)}
          type="checkbox"
          className="mr-3"
          id={`${reason}`}
          name="reason"
          value={reason}
          onClick={handelReasonSelect}
        />
      </div>
    ));
  };
  const RenderComment = () => {
    return (
      <Form.Control
        value={ratingComment}
        onChange={handelRatingCommentChange}
        as="textarea"
        rows="3"
      />
    );
  };

  return (
    <flex>
      <div className="outer">
        <div className="two">
          <HeaderComponent history={history} />
          <Col>
            <Row>
              <Col xs={12} md={12} className="pt-3">
                <Form>
                  {RenderRatingStars()}
                  {RenderReasons()}
                  {RenderComment()}
                </Form>
              </Col>
              <Col xs={12} md={12} className="pt-3 text-center">
                <Button variant="success" onClick={handleSubmit}>
                  {t('ChooseWorkerPage.ratingModal.confirmButtonText')}
                </Button>
              </Col>
              <div
                style={{
                  minHeight: '5rem',
                }}
              />
            </Row>
          </Col>
        </div>
      </div>
    </flex>
  );
}
