import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import React from 'react';

import './Login.css';

export default function LoginComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    username,
    onUsernameFieldChange,
    onClick,
    isFetchingCheckIfAccountExist,
    isErrorCheckIfAccountExist,
    checkIfAccountExistErrorMessage,
  } = props;

  return (
    <Col className="backgroundImage text-white d-flex align-items-center justify-content-center text-center">
      <Form noValidate>
        <Row className="">
          <Col xs={12} md={12}>
            <h1>{t('AppName')}</h1>
          </Col>
          <Col xs={12} md={12}>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>+966</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                name="username"
                value={username.value}
                onChange={onUsernameFieldChange}
                readOnly={isFetchingCheckIfAccountExist}
                placeholder={t('UserName')}
                pattern="[0-9]{9}"
                autoComplete="off"
                required
                autoFocus
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={12}>
            <span className="text-danger">{username.message[i18n.language]}</span>
            {isErrorCheckIfAccountExist && (
              <span className="text-danger">{checkIfAccountExistErrorMessage[i18n.language]}</span>
            )}
          </Col>
          <Col xs={12} md={12}>
            <Button
              variant="primary"
              className="mb-2"
              type="submit"
              disabled={isFetchingCheckIfAccountExist}
              onClick={!isFetchingCheckIfAccountExist ? onClick : null}
            >
              {isFetchingCheckIfAccountExist ? t('LoginLoadingButtonText') : t('LoginButtonText')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}
