import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Image, Carousel, Button } from 'react-bootstrap';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ls from 'local-storage';
import * as jwt from 'jsonwebtoken';
import {
  getAllCustomerAds,
  validateThePromoCodeForUsage,
  orderNewService,
  clearServicesStore,
} from '../../store/actions';
import Map from '../../components/Map';
import OrderNewRequestConfirmationModal from '../../components/OrderNewRequestConfirmationModal';
import AlertComponent from '../../components/_shared/Alert';
import PromoCodeModalComponent from '../../components/PromoCodeModalComponent';
import Footer from '../../components/_shared/Footer';
import { GoogleMapsAPI } from '../../config/index';
import LoadingComponent from '../../components/_shared/Loading';
import ReconditioningServiceRequirementsModal from '../../components/ReconditioningServiceRequirementsModal';
import { pricingListConstant } from '../../constants';
import './Services.css';

function ServicesTranslationComponent(props) {
  const { ads, field, chooseService } = props;
  const { t, i18n } = useTranslation('common');

  const carouselItems = ads.map((ad) => {
    const { _id, title, imageUrl } = ad;
    return (
      <Carousel.Item key={_id}>
        <img className="d-block w-100" src={imageUrl[i18n.language]} alt="" />
        <Carousel.Caption>
          <p>{title[i18n.language]}</p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return (
    <>
      {!field && (
        <div>
          <Row className="p-2">
            <Col xs={3} md={3}>
              <Card
                key="request1"
                className="m-0"
                onClick={() => (window.location = 'https://fannistore.com/')}
              >
                <Card.Body className="p-2">
                  <Row className="justify-content-center">
                    <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                    <span>{t('servicesPage.store')}</span>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="p-2">
            <Col xs={12} md={12}>
              <Carousel indicators keyboard={false} prevIcon="" nextIcon="">
                {carouselItems}
              </Carousel>
            </Col>
          </Row>
          <Row className="p-2">
            <Col>
              <Card className="cardBackground" key="request1">
                <Card.Body>
                  <Row className="justify-content-center">
                    <span>{t('servicesPage.servicesHeaderText')}ا</span>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="p-2">
            <Col>
              <Card className="cardBackground" key="request2">
                <Card.Body>
                  <Row>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('mobiles')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/mobiles.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.mobiles')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('reconditioning')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/reconditioning.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.reconditioning')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('paved')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/paved.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.paved')}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('electrical')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/electrical.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.electrical')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('plumbing')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/plumbing.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.plumbing')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('carpentry')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/carpentry.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.carpentry')}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('air-conditioning')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/air-conditioning.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.air-conditioning')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('painting')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/painting.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.painting')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('satellite-tv')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/satellite-tv.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.satellite-tv')}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-2 text-center">
                      <div
                        className="circle"
                        onClick={() => chooseService('furniture-transportation')}
                      >
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/furniture-transportation.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.furniture-transportation')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('cleaning')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/cleaning.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.cleaning')}</span>
                    </Col>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('home-appliances')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/home-appliances.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.home-appliances')}</span>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '3rem !important' }}>
                    <Col className="p-2 text-center">
                      <div className="circle" onClick={() => chooseService('cameras')}>
                        <Image
                          className="to-be-white"
                          src={`${process.env.PUBLIC_URL}/images/services/cameras.png`}
                          width={35}
                          height={35}
                        />
                      </div>
                      <br />
                      <span>{t('Fields.cameras')}</span>
                    </Col>
                    <Col className="p-2 text-center" />
                    <Col className="p-2 text-center" />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

function MapTranslationComponent(props) {
  const { t } = useTranslation('common');
  const {
    field,
    onClickOrderNewRequestButton,
    chooseService,
    promoCode,
    showPromoCodeModal,
    onPromoCodeFieldChange,
    isFetchingValidatePromoCode,
    isSuccessValidatePromoCode,
    foundPromoCode,
    togglePromoCodeModal,
    isErrorPromoCode,
    promoCodeErrorMessage,
  } = props;

  return (
    <>
      {field && (
        <>
          <div
            style={{
              position: 'absolute',
              bottom: '6rem',
              width: '100%',
            }}
          >
            <Row className="p-1">
              <Col>
                <Button
                  variant=""
                  className="p-2 btn-block gray-background"
                  type="submit"
                  onClick={() => onClickOrderNewRequestButton()}
                >
                  {t('servicesPage.orderButtonText')}
                </Button>
              </Col>
              {field !== 'reconditioning' && (
                <Col>
                  {!showPromoCodeModal && (
                    <Button
                      variant={!isSuccessValidatePromoCode ? 'outline-secondary' : 'success'}
                      className="p-2 btn-block white-background"
                      type="submit"
                      disabled={isSuccessValidatePromoCode}
                      onClick={() => togglePromoCodeModal()}
                    >
                      {!isSuccessValidatePromoCode
                        ? t('servicesPage.promoCode.addPromoCodeButtonText')
                        : t('servicesPage.promoCode.promoCodeAcceptedButtonText')}
                    </Button>
                  )}
                  {showPromoCodeModal && (
                    <PromoCodeModalComponent
                      show={showPromoCodeModal}
                      showPromoCodeModal={showPromoCodeModal}
                      onPromoCodeFieldChange={onPromoCodeFieldChange}
                      isFetchingValidatePromoCode={isFetchingValidatePromoCode}
                      foundPromoCode={foundPromoCode}
                      togglePromoCodeModal={togglePromoCodeModal}
                      promoCode={promoCode}
                      isErrorPromoCode={isErrorPromoCode}
                      promoCodeErrorMessage={promoCodeErrorMessage}
                    />
                  )}
                </Col>
              )}
            </Row>
            <Row className="p-1">
              {field !== 'reconditioning' && (
                <Col>
                  <Button
                    variant=""
                    className="p-2 btn-block gray-background"
                    type="submit"
                    onClick={() => {
                      window.location = `https://fanni.sa/price-list/${pricingListConstant[field]}`;
                    }}
                  >
                    {t('servicesPage.priceListButtonText')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  variant="outline-secondary"
                  className="p-2 btn-block white-background"
                  type="submit"
                  onClick={() => chooseService(null)}
                >
                  {t('servicesPage.cancelOrderButtonText')}
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

class ServicesContainer extends Component {
  debouncedPromoCodeSearch = debounce(function (promoCode) {
    const { validateThePromoCodeForUsage: validateThePromoCodeForUsageAction } = this.props;

    validateThePromoCodeForUsageAction(promoCode);
  }, 1000);

  constructor(props) {
    super(props);
    this.state = {
      markerPosition: {
        lat: 0,
        lng: 0,
      },
      showOrderNewRequestConfirmationModal: false,
      showReconditioningServiceRequirementsModal: false,
      field: null,
      fieldRequirements: '',
      promoCode: '',
      showPromoCodeModal: false,
    };
  }

  componentDidMount() {
    const { getAllCustomerAds: getAllCustomerAdsAction } = this.props;

    getAllCustomerAdsAction();
  }

  chooseService = (serviceName) => {
    if (!serviceName) {
      const { clearServicesStore } = this.props;
      clearServicesStore();
      return this.setState({
        promoCode: '',
        field: serviceName,
        showPromoCodeModal: false,
      });
    }

    return this.setState({ field: serviceName });
  };

  onClickOrderNewRequestButton = () => {
    const {
      field,
      showOrderNewRequestConfirmationModal,
      showReconditioningServiceRequirementsModal,
    } = this.state;
    if (field !== 'reconditioning') {
      return this.setState({
        showOrderNewRequestConfirmationModal: !showOrderNewRequestConfirmationModal,
      });
    }

    return this.setState({
      showReconditioningServiceRequirementsModal: !showReconditioningServiceRequirementsModal,
    });
  };

  confirmOrderNewRequest = () => {
    const {
      field,
      markerPosition: { lat, lng },
      fieldRequirements,
    } = this.state;
    const { orderNewService: orderNewServiceAction, history, foundPromoCode } = this.props;

    const token = ls.get('token');
    const {
      location: { pathname },
    } = window;

    if (token) {
      const decodeToken = jwt.decode(token, { complete: true });
      if (decodeToken.payload.exp < Math.round(Date.now() / 1000)) {
        // Invalid Token
        ls.clear();
        history.push('/login');
      } else {
        const payload = { location: { latitude: lat, longitude: lng }, field };

        if (foundPromoCode) {
          payload.promoCode = foundPromoCode.code;
        }

        if (fieldRequirements) {
          payload.fieldRequirements = fieldRequirements;
        }

        orderNewServiceAction(payload, history);
      }
    } else if (pathname !== 'login') {
      history.push('/login');
    }
  };

  onPromoCodeFieldChange = (e) => {
    this.setState({ promoCode: e.target.value }, () => {
      const { promoCode } = this.state;
      if (promoCode) {
        this.debouncedPromoCodeSearch(promoCode);
      }
    });
  };

  handleLocationChange = (lat, lng) => {
    this.setState({
      markerPosition: {
        lat,
        lng,
      },
    });
  };

  togglePromoCodeModal = () => {
    this.setState((prevState) => ({ showPromoCodeModal: !prevState.showPromoCodeModal }));
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      field,
      showOrderNewRequestConfirmationModal,
      promoCode,
      showPromoCodeModal,
      markerPosition,
      fieldRequirements,
      showReconditioningServiceRequirementsModal,
    } = this.state;
    const {
      ads,
      history,
      foundPromoCode,
      isFetchingOrderNewService,
      isErrorOrderNewService,
      orderNewServiceErrorMessage,
      getCustomerAdsErrorMessage,
      isSuccessValidatePromoCode,
      isErrorPromoCode,
      promoCodeErrorMessage,
      isFetchingCustomerAds,
    } = this.props;

    if (isFetchingCustomerAds) {
      return <LoadingComponent />;
    }

    return (
      <flex>
        <div className="outer">
          <div className="two">
            <div className="outer">
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
                loadingElement={<div />}
                containerElement={<div />}
                mapElement={
                  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                }
                field={field}
                markerPosition={markerPosition}
                handleLocationChange={this.handleLocationChange}
              />
              <div className="two">
                <MapTranslationComponent
                  onPlaceSelectedFromAutoComplete={this.onPlaceSelectedFromAutoComplete}
                  showOrderNewRequestConfirmationModal={showOrderNewRequestConfirmationModal}
                  onClickOrderNewRequestButton={this.onClickOrderNewRequestButton}
                  chooseService={this.chooseService}
                  history={history}
                  field={field}
                  promoCode={promoCode}
                  showPromoCodeModal={showPromoCodeModal}
                  onPromoCodeFieldChange={this.onPromoCodeFieldChange}
                  isSuccessValidatePromoCode={isSuccessValidatePromoCode}
                  foundPromoCode={foundPromoCode}
                  togglePromoCodeModal={this.togglePromoCodeModal}
                  isErrorPromoCode={isErrorPromoCode}
                  promoCodeErrorMessage={promoCodeErrorMessage}
                />
                <ServicesTranslationComponent
                  field={field}
                  ads={ads}
                  chooseService={this.chooseService}
                />
                <OrderNewRequestConfirmationModal
                  show={showOrderNewRequestConfirmationModal}
                  confirmOrderNewRequest={this.confirmOrderNewRequest}
                  onClickOrderNewRequestButton={this.onClickOrderNewRequestButton}
                  isFetchingOrderNewService={isFetchingOrderNewService}
                  isErrorOrderNewService={isErrorOrderNewService}
                  orderNewServiceErrorMessage={orderNewServiceErrorMessage}
                />
                <ReconditioningServiceRequirementsModal
                  field={field}
                  show={showReconditioningServiceRequirementsModal}
                  confirmOrderNewRequest={this.confirmOrderNewRequest}
                  onClickOrderNewRequestButton={this.onClickOrderNewRequestButton}
                  history={history}
                  isFetchingOrderNewService={isFetchingOrderNewService}
                  isErrorOrderNewService={isErrorOrderNewService}
                  orderNewServiceErrorMessage={orderNewServiceErrorMessage}
                  fieldRequirements={fieldRequirements}
                  handleInputChange={this.handleInputChange}
                />

                {getCustomerAdsErrorMessage.ar && (
                  <AlertComponent type="error" content={getCustomerAdsErrorMessage} />
                )}
              </div>
            </div>
          </div>
          <div className="three">
            <Footer url="services" />
          </div>
        </div>
      </flex>
    );
  }
}

ServicesContainer.displayName = 'ServicesContainer';

const mapStateToProps = (state) => ({
  isFetchingOrderNewService: state.services.orderNewService.isFetching,
  isSuccessOrderNewService: state.services.orderNewService.isSuccess,
  isErrorOrderNewService: state.services.orderNewService.isFail.isError,
  orderNewServiceErrorMessage: state.services.orderNewService.isFail.message,

  isFetchingValidatePromoCode: state.services.validatePromoCode.isFetching,
  isSuccessValidatePromoCode: state.services.validatePromoCode.isSuccess,
  isErrorValidatePromoCode: state.services.validatePromoCode.isFail.isError,
  validatePromoCodeErrorMessage: state.services.validatePromoCode.isFail.message,

  isFetchingCustomerAds: state.services.getCustomerAds.isFetching,
  getCustomerAdsErrorMessage: state.services.getCustomerAds.isFail.message,
  ads: state.services.ads,

  isErrorPromoCode: state.services.validatePromoCode.isFail.isError,
  promoCodeErrorMessage: state.services.validatePromoCode.isFail.message,
  foundPromoCode: state.services.promoCode,
});
const actionCreators = {
  orderNewService,
  validateThePromoCodeForUsage,
  getAllCustomerAds,
  clearServicesStore,
};

export default connect(mapStateToProps, actionCreators)(ServicesContainer);
