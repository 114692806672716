import { appliedWorkersConstants } from '../../constants';
import { appliedWorkersService } from '../../services';
import { Status } from '../../services/api';

export const appliedWorkersActions = {
  acceptWorker,
};

function acceptWorker(data, requestPrettyId, history) {
  return async (dispatch) => {
    dispatch(request());
    const response = await appliedWorkersService.acceptWorker(data);

    if (Status.isSuccess(response.status)) {
      dispatch(success());
      return history.push(`/request-details/${requestPrettyId}`);
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: appliedWorkersConstants.ACCEPT_WORKER_REQUEST };
  }

  function success() {
    return { type: appliedWorkersConstants.ACCEPT_WORKER_SUCCESS };
  }

  function failure(error) {
    return { type: appliedWorkersConstants.ACCEPT_WORKER_FAILURE, error };
  }
}
