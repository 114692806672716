import React, { Component } from 'react';
import { connect } from 'react-redux';
import OthersComponent from '../../components/Others';
import { usersActions } from '../../store/actions';

class OthersContainer extends Component {
  constructor(props) {
    super(props);

    this.downloadAppLinkBasedOnOperatingSystem = navigator.platform === 'android' ? '' : '';

    this.state = {
      language: 'ar',
      showChangeLanguageModal: false,
    };
  }

  componentDidMount() {
    const { getUserDetails } = this.props;

    getUserDetails();
  }

  toggleChangeLanguageModal = () => {
    this.setState((prevState) => ({ showChangeLanguageModal: !prevState.showChangeLanguageModal }));
  };

  onChangeInputValue = (e) => {
    this.toggleChangeLanguageModal();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { language, showChangeLanguageModal } = this.state;
    const {
      userDetails,
      isFetchingGetUserDetails,
      isErrorGetUserDetails,
      errorMessageGetUserDetails,
      history,
    } = this.props;

    return (
      <OthersComponent
        user={userDetails}
        downloadAppLinkBasedOnOperatingSystem={this.downloadAppLinkBasedOnOperatingSystem}
        toggleChangeLanguageModal={this.toggleChangeLanguageModal}
        language={language}
        showChangeLanguageModal={showChangeLanguageModal}
        onChangeInputValue={this.onChangeInputValue}
        isFetchingGetUserDetails={isFetchingGetUserDetails}
        isErrorGetUserDetails={isErrorGetUserDetails}
        errorMessageGetUserDetails={errorMessageGetUserDetails}
        history={history}
      />
    );
  }
}
OthersContainer.displayName = 'OthersContainer';

const mapStateToProps = (state) => ({
  userDetails: state.users.userDetails,
  isFetchingGetUserDetails: state.users.getUserDetails.isFetching,
  isErrorGetUserDetails: state.users.getUserDetails.isFail.isError,
  errorMessageGetUserDetails: state.users.getUserDetails.isFail.message,
});

const actionCreators = {
  getUserDetails: usersActions.getUserDetails,
};

export default connect(mapStateToProps, actionCreators)(OthersContainer);
