import ls from 'local-storage';
import { Api, Urls } from './api';

function acceptWorker(data) {
  const { requests } = Urls;
  const url = requests.activate;
  const api = new Api();
  const token = ls.get('token');

  const header = [{ key: 'token', value: token }];

  return api.patch(url, data, header);
}

export const appliedWorkersService = {
  acceptWorker,
};
