import React, { Component } from 'react';
import './CancelRequestModal.css';
import { connect } from 'react-redux';
import { getAllConfigurations, requestsActions } from '../../store/actions';
import ServicesListModal from './ServicesListModal';
import HighPriceReasonModal from './HighPriceReasonModal';
import TechnicianWasNoShowModal from './TechnicianWasNoShowModal';
import TechnicianNotSuitableReasonModal from './TechnicianNotSuitableReasonModal';
import TechnicianRequestedCancellationModal from './TechnicianRequestedCancellationModal';
import OtherReasonModal from './OtherReasonModal';
import CancellationReasonsListModal from './CancellationReasonsListModal';

class CancelRequestModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancellationReason: null,
      cancellationReasonComment: '',

      showServicesListModal: false,
      showHighPriceReasonModal: false,
      showTechnicianWasNoShowModalReasonModal: false,
      showTechnicianNotSuitableReasonModal: false,
      showTechnicianRequestedCancellationReasonModal: false,
      showOtherReasonModal: false,
    };
  }

  componentDidMount() {
    const { getAllConfigurations } = this.props;

    getAllConfigurations();
  }

  handelReasonSelect = (e) => {
    const { code } = e.target.dataset;
    this.setState({ cancellationReason: code });
    const { toggleCancellationModal } = this.props;

    if (code === 'highPrice') {
      this.toggleTheCancellationReasonModal('showHighPriceReasonModal');
    } else if (code === 'technicianNotSuitable') {
      this.toggleTheCancellationReasonModal('showTechnicianNotSuitableReasonModal');
    } else if (code === 'technicianWasANoShow') {
      this.toggleTheCancellationReasonModal('showTechnicianWasNoShowModalReasonModal');
    } else if (code === 'technicianRequestedCancellation') {
      this.toggleTheCancellationReasonModal('showTechnicianRequestedCancellationReasonModal');
    } else if (code === 'needNewField') {
      this.toggleTheCancellationReasonModal('showServicesListModal');
    } else if (code === 'other') {
      this.toggleTheCancellationReasonModal('showOtherReasonModal');
    }
    toggleCancellationModal();
  };

  handelReasonCommentChange = (e) => this.setState({ cancellationReasonComment: e.target.value });

  toggleTheCancellationReasonModal = (stateModalName) => {
    if (this.state[stateModalName]) {
      this.setState((prevState) => ({
        [stateModalName]: !prevState[stateModalName],
        cancellationReason: null,
        cancellationReasonComment: '',
      }));
    } else {
      this.setState((prevState) => ({
        [stateModalName]: !prevState[stateModalName],
      }));
    }
  };

  cancelTheRequestAction = (field) => {
    const { cancellationReason, cancellationReasonComment } = this.state;
    const { request, cancelActiveRequest, history } = this.props;

    cancelActiveRequest(
      {
        request,
        field,
        cancellationReason,
        cancellationReasonComment,
      },
      history,
    );
  };

  render() {
    const {
      cancellationReasonComment,
      cancellationReason,
      showServicesListModal,
      showHighPriceReasonModal,
      showTechnicianNotSuitableReasonModal,
      showTechnicianRequestedCancellationReasonModal,
      showOtherReasonModal,
      showTechnicianWasNoShowModalReasonModal,
    } = this.state;
    const {
      request,
      isModelOpen,
      toggleCancellationModal,
      configs,
      isErrorCancelActiveRequest,
      cancelActiveRequestErrorMessage,
    } = this.props;

    return (
      <>
        <CancellationReasonsListModal
          isModelOpen={isModelOpen}
          toggleCancellationModal={toggleCancellationModal}
          configs={configs}
          cancellationReason={cancellationReason}
          handelReasonSelect={this.handelReasonSelect}
        />

        {cancellationReason && cancellationReason === 'highPrice' && (
          <HighPriceReasonModal
            configs={configs}
            showHighPriceReasonModal={showHighPriceReasonModal}
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}

        {cancellationReason && cancellationReason === 'technicianNotSuitable' && (
          <TechnicianNotSuitableReasonModal
            configs={configs}
            showTechnicianNotSuitableReasonModal={showTechnicianNotSuitableReasonModal}
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}
        {cancellationReason && cancellationReason === 'technicianWasANoShow' && (
          <TechnicianWasNoShowModal
            configs={configs}
            showTechnicianWasNoShowModalReasonModal={showTechnicianWasNoShowModalReasonModal}
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}

        {cancellationReason && cancellationReason === 'technicianRequestedCancellation' && (
          <TechnicianRequestedCancellationModal
            request={request}
            showTechnicianRequestedCancellationReasonModal={
              showTechnicianRequestedCancellationReasonModal
            }
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}

        {cancellationReason && cancellationReason === 'needNewField' && (
          <ServicesListModal
            configs={configs}
            showServicesListModal={showServicesListModal}
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}

        {cancellationReason && cancellationReason === 'other' && (
          <OtherReasonModal
            configs={configs}
            showOtherReasonModal={showOtherReasonModal}
            toggleTheCancellationReasonModal={this.toggleTheCancellationReasonModal}
            cancellationReasonComment={cancellationReasonComment}
            handelReasonCommentChange={this.handelReasonCommentChange}
            cancelTheRequestAction={this.cancelTheRequestAction}
            isErrorCancelActiveRequest={isErrorCancelActiveRequest}
            cancelActiveRequestErrorMessage={cancelActiveRequestErrorMessage}
          />
        )}
      </>
    );
  }
}
CancelRequestModalComponent.displayName = 'CancelRequestModalComponent';

const mapStateToProps = (state) => ({
  configs: state.services.configs,
  isErrorCancelActiveRequest: state.requests.cancelActiveRequest.isFail.isError,
  cancelActiveRequestErrorMessage: state.requests.cancelActiveRequest.isFail.message,
});

const actionCreators = {
  getAllConfigurations,
  cancelActiveRequest: requestsActions.cancelActiveRequest,
};

export default connect(mapStateToProps, actionCreators)(CancelRequestModalComponent);
