import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { GoogleMapsAPI } from '../../config';

const GoogleMapWrapper = compose(
  withScriptjs,
  withGoogleMap,
)((props) => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={props.defaultCenter}
    defaultOptions={{
      disableDefaultUI: true,
      draggable: false,
      zoomControl: false,
    }}
  >
    {props.location && (
      <Marker
        crossOnDrag={false}
        draggable={false}
        icon={{
          url: `${process.env.PUBLIC_URL}/images/pin.png`,
        }}
        position={{ lat: Number(props.location.latitude), lng: Number(props.location.longitude) }}
      />
    )}
  </GoogleMap>
));

const RequestMap = (props) => {
  const { location } = props;
  const defaultMapCenter = {
    lat: Number(location.latitude),
    lng: Number(location.longitude),
  };

  return (
    <GoogleMapWrapper
      location={location}
      defaultCenter={defaultMapCenter}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '260px', width: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );
};
RequestMap.displayName = 'RequestMap';

RequestMap.propTypes = {
  location: PropTypes.shape({}),
};

RequestMap.defaultProps = {
  location: undefined,
};

export default RequestMap;
