import { Button, Col, Row, Spinner } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CancelRequestModal from '../CancelRequestModal';

export default function ButtonsSection({
  history,
  showCancellationModal,
  isFetchingRejectTheInvoice,
  request,
  status,
  requestFromFirebase,
  worker,
  addWorkerToFavorite,
  isFetchingAddWorkerToFavorites,
  isErrorAddWorkerToFavorites,
  addWorkerToFavoritesErrorMessage,
  toggleCancellationModal,
  pricingListConstant,
  field,
  workersWage,
  rejectTheInvoice,
  requestId,
  isErrorRejectTheInvoice,
  errorMessageRejectTheInvoice,
  isFetchingCreateOrderForTheInvoiceInStcPay,
  isErrorCreateOrderForTheInvoiceInStcPay,
  errorMessageCreateOrderForTheInvoiceInStcPay,
  createOrderForTheInvoiceInStcPay,
}) {
  const { t, i18n } = useTranslation('common');

  return (
    <>
      {status === 'done' && (
        <Row className="justify-content-center m-0 pt-3 pl-3 pr-3">
          <Link
            to={`/request-details/${request.requestPrettyId}/rate`}
            className="btn btn-block btn-lg btn-fanni"
          >
            {t('RequestDetailsPage.rateWorkerButtonText')}
          </Link>
        </Row>
      )}

      <Row className="pt-3 pl-3 pr-3">
        <Col className="p-0">
          <a className="btn btn-block btn-lg btn-fanni" href="tel:+966920033837">
            {t('RequestDetailsPage.help')}
          </a>
        </Col>
        {requestFromFirebase &&
          requestFromFirebase.status === 'active' &&
          requestFromFirebase.workersWage === 0 && (
          <Col xs={7} className="pt-0 pb-0 pr-1 pl-0">
            <a
              className="btn btn-block btn-lg btn-fanni"
              href={`tel:+${worker && worker.username}`}
            >
              {t('RequestDetailsPage.contactWorkerButtonText')}
            </a>
          </Col>
        )}
        {['done', 'reviewed'].includes(status) && (
          <Col xs={9} className="pt-0 pb-0 pr-1 pl-0">
            <Button
              variant=""
              className="btn btn-block btn-lg btn-fanni f-btn-small"
              type="submit"
              onClick={() => addWorkerToFavorite(worker && worker._id)}
            >
              {isFetchingAddWorkerToFavorites && (
                <Spinner
                  aria-hidden="true"
                  size="sm"
                  role="status"
                  animation="border"
                  variant="info"
                />
              )}
              {t('RequestDetailsPage.addWorkerToYourFavoritesListButtonText')}
            </Button>
          </Col>
        )}
      </Row>
      {isErrorAddWorkerToFavorites && (
        <span className="text-danger">{addWorkerToFavoritesErrorMessage[i18n.language]}</span>
      )}

      {requestFromFirebase &&
        requestFromFirebase.status === 'active' &&
        requestFromFirebase.workersWage === 0 && (
        <>
          <Row className="pt-2 pl-3 pr-3">
            <Col className="p-0">
              <Button
                variant=""
                type="submit"
                className="btn btn-block btn-lg btn-fanni"
                onClick={toggleCancellationModal}
              >
                {t('RequestDetailsPage.cancelRequestButtonText')}
              </Button>
            </Col>
          </Row>

          <CancelRequestModal
            history={history}
            request={request}
            isModelOpen={showCancellationModal}
            toggleCancellationModal={toggleCancellationModal}
          />

          <Row className="pt-2 pl-3 pr-3">
            <Col className="p-0">
              <Button
                variant=""
                className="btn btn-block btn-lg btn-fanni"
                onClick={() => {
                  window.location = `https://fanni.sa/price-list/${pricingListConstant[field]}`;
                }}
              >
                {t('servicesPage.priceListButtonText')}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {status === 'active' &&
        ((requestFromFirebase && requestFromFirebase.workersWage > 0) || workersWage > 0) && (
        <>
          <Row className="pt-2 pl-3 pr-3">
            <Col className="p-0">
              <Button
                variant=""
                className="btn btn-block btn-lg btn-fanni"
                onClick={() =>
                  createOrderForTheInvoiceInStcPay(requestId, window.STCPAY.getSessionToken())
                }
              >
                {isFetchingCreateOrderForTheInvoiceInStcPay && (
                  <Spinner
                    aria-hidden="true"
                    size="sm"
                    role="status"
                    animation="border"
                    variant="primary"
                  />
                )}
                {t('RequestDetailsPage.payButtonText')}
              </Button>
            </Col>
          </Row>
          {isErrorCreateOrderForTheInvoiceInStcPay && (
            <span className="text-danger">
              {errorMessageCreateOrderForTheInvoiceInStcPay[i18n.language]}
            </span>
          )}
          <Row className="pt-2 pl-3 pr-3">
            <Col className="p-0">
              <Button
                variant=""
                className="btn btn-block btn-lg btn-fanni-white"
                onClick={() => rejectTheInvoice(requestId)}
              >
                {isFetchingRejectTheInvoice && (
                  <Spinner
                    aria-hidden="true"
                    size="sm"
                    role="status"
                    animation="border"
                    variant="primary"
                  />
                )}
                {t('RequestDetailsPage.invoiceObjectionButtonText')}
              </Button>
            </Col>
          </Row>
          {isErrorRejectTheInvoice && (
            <span className="text-danger">{errorMessageRejectTheInvoice[i18n.language]}</span>
          )}
        </>
      )}
    </>
  );
}
