import React, { Component } from 'react';
import { connect } from 'react-redux';

import RequestsComponent from '../../components/Requests';

class RequestsContainer extends Component {
  render() {
    const { history } = this.props;

    return <RequestsComponent history={history} />;
  }
}

RequestsContainer.displayName = 'RequestsContainer';

function mapState(state) {
  const { requests } = state;

  return { requests };
}

const actionCreators = {};

export default connect(mapState, actionCreators)(RequestsContainer);
