import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 5000,
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
  rtl: i18next.dir(i18next.language) === 'rtl',
});

function AlertComponent(props) {
  const { content, type } = props;
  const {
    i18n: { language },
  } = useTranslation('common');

  return <div>{toast[type](content[language])}</div>;
}

export default React.memo(AlertComponent);
