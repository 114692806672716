import ls from 'local-storage';
import { Api, Urls } from './api';

function addWorkerToFavorite(workerId) {
  const { customers } = Urls;
  const url = customers.addWorkerToFavorite;
  const token = ls.get('token');
  const header = [{ key: 'token', value: token }];
  const api = new Api();

  return api.post(url, { workerId }, header);
}

function rateWorker(data) {
  const { customers } = Urls;
  const url = customers.reviewWorker;
  const token = ls.get('token');
  const header = [{ key: 'token', value: token }];
  const api = new Api();

  return api.patch(url, data, header);
}

function getUserDetails() {
  const { customers } = Urls;
  const url = `${customers.getUserDetailsUrl}`;
  const token = ls.get('token');
  const header = [{ key: 'token', value: token }];
  const api = new Api();

  return api.get(url, header);
}

export const usersService = {
  addWorkerToFavorite,
  rateWorker,
  getUserDetails,
};
