import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faDollarSign,
  faDownload,
  faInfoCircle,
  faLanguage,
  faPhoneAlt,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import Footer from '../_shared/Footer';
import ChangeLanguageModal from '../ChangeLanguageModal';

import './Others.css';
import LoadingComponent from '../_shared/Loading';
import HeaderComponent from "../_shared/Header";

export default function OthersComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    user: { name, username, credits },
    toggleChangeLanguageModal,
    downloadAppLinkBasedOnOperatingSystem,
    language,
    showChangeLanguageModal,
    onChangeInputValue,
    isFetchingGetUserDetails,
    isErrorGetUserDetails,
    errorMessageGetUserDetails,
    history,
  } = props;

  if (isFetchingGetUserDetails) {
    return <LoadingComponent />;
  }

  return (
    <flex>
      <div className="backgroundImage outer">
        <div className="two">
          <HeaderComponent history={history} isUseBackgroundImage />
          <div className="text-white">
            <Row className="m-0 pt-3">
              <Col xs={2} md={2} className="text-center pt-3">
                <img
                  className="to-be-white"
                  src={`${process.env.PUBLIC_URL}/images/ic_person_call.png`}
                  width={55}
                  height={50}
                  alt=""
                />
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={12} className="pb-2">
                    {name}
                  </Col>
                  <Col xs={12} md={12}>
                    {username}
                  </Col>
                </Row>
              </Col>
            </Row>
            {isErrorGetUserDetails && (
              <div className="text-center">
                <span className="text-danger">{errorMessageGetUserDetails[i18n.language]}</span>
              </div>
            )}
            <Row className="m-0 pt-5">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faCreditCard} size="lg" />
              </Col>
              <Col xs={2} md={2}>
                <span>{t('othersPage.wallet')}</span>
              </Col>
              <Col xs={1} md={1} />
              <Col>
                <span>
                  {credits} {t('currency')}
                </span>
              </Col>
            </Row>
            <Row className="m-0 pt-3">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faDownload} size="lg" />
              </Col>
              <Col>
                <a className="white-active" href={downloadAppLinkBasedOnOperatingSystem}>
                  {t('othersPage.downloadApp')}
                </a>
              </Col>
            </Row>
            <Row className="m-0 pt-3">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </Col>
              <Col>
                <Link className="white-active" to="/about">
                  {t('othersPage.aboutUs')}
                </Link>
              </Col>
            </Row>
            <Row className="m-0 pt-3">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faShoppingCart} size="lg" />
              </Col>
              <Col>
                <a className="white-active" href="https://fannistore.com">
                  {t('othersPage.store')}
                </a>
              </Col>
            </Row>
            <Row className="m-0 pt-3">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faDollarSign} size="lg" />
              </Col>
              <Col>
                <a className="white-active" href="https://fanni.sa/price-list">
                  {t('othersPage.pricingList')}
                </a>
              </Col>
            </Row>
            <Row className="m-0 pt-3">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
              </Col>
              <Col>
                <a className="white-active" href="tel:+966920033837">
                  {t('othersPage.contactUs')}
                </a>
              </Col>
            </Row>
            <Row className="m-0 pt-3 pb-4">
              <Col xs={1} md={1}>
                <FontAwesomeIcon icon={faLanguage} size="lg" />
              </Col>
              <Col>
                <Form.Label htmlFor="inlineFormCustomSelect" onClick={toggleChangeLanguageModal}>
                  {t('othersPage.languageLabelText')}
                </Form.Label>
              </Col>
            </Row>
          </div>
        </div>
        <div className="three">
          <Footer url="others" />
        </div>
      </div>
      <ChangeLanguageModal
        show={showChangeLanguageModal}
        toggleChangeLanguageModal={toggleChangeLanguageModal}
        language={language}
        onChangeInputValue={onChangeInputValue}
      />
    </flex>
  );
}
