import { servicesConstants } from '../../constants';

const initialState = {
  ads: [],
  configs: {
    enums: {
      cancellingPendingRequestsWithAppliedWorkers: [],
      cancellingPendingRequestsWithoutAppliedWorkers: [],
    },
  },
  request: undefined,
  promoCode: undefined,
  orderNewService: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  validatePromoCode: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getCustomerAds: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getAllConfigs: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const { request, promoCode, ads, configs, err } = payload;

  switch (type) {
    case servicesConstants.ADD_NEW_REQUEST: {
      return {
        ...state,
        orderNewService: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.ADD_NEW_REQUEST_SUCCESS: {
      return {
        ...state,
        request,
        orderNewService: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.ADD_NEW_REQUEST_FAILED: {
      return {
        ...state,
        orderNewService: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }

    case servicesConstants.USE_PROMO_CODE: {
      return {
        ...state,
        validatePromoCode: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.USE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        promoCode,
        validatePromoCode: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.USE_PROMO_CODE_FAILED: {
      return {
        ...state,
        validatePromoCode: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }

    case servicesConstants.GET_CUSTOMER_ADS: {
      return {
        ...state,
        getCustomerAds: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.GET_CUSTOMER_ADS_SUCCESS: {
      return {
        ...state,
        ads,
        getCustomerAds: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.GET_CUSTOMER_ADS_FAILED: {
      return {
        ...state,
        getCustomerAds: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }

    case servicesConstants.GET_ALL_CONFIGS: {
      return {
        ...state,
        getAllConfigs: {
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.GET_ALL_CONFIGS_SUCCESS: {
      return {
        ...state,
        configs,
        getAllConfigs: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
            message: {
              ar: '',
              en: '',
            },
          },
        },
      };
    }
    case servicesConstants.GET_ALL_CONFIGS_FAIL: {
      return {
        ...state,
        getAllConfigs: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }

    case servicesConstants.CLEAR: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
