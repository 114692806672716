export const pricingListConstant = {
  'satellite-tv': '#satellite-tv',
  plumbing: '#plumbing',
  painting: '#painting',
  'air-conditioning': '#air-conditioning',
  'home-appliances': '#home-appliances',
  electrical: '#electrical',
  carpentry: '#carpentry',
  'furniture-transportation': '#furniture',
  cameras: '#camera',
  slabbing: '#slabbing',
  mobiles: '#mobile',
  reconditioning: '',
  paved: '',
  cleaning: '',
};
