import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { checkIfAccountExist } from '../../store/actions';
import LoginComponent from '../../components/Login';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: '', isValid: true, message: { ar: '', en: '' } },
    };
  }

  onUsernameFieldChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
      },
    });
  };

  formIsValid() {
    const { username } = this.state;
    let isFormValid = true;

    if (!validator.isByteLength(username.value, { min: 3, max: 15 })) {
      isFormValid = false;
      username.isValid = false;
      username.message = {
        ar: 'برجاء ادخال رقم الهاتف صحيحا',
        en: 'Please, Enter The Phone Number Correct',
      };
    }

    if (!isFormValid) {
      this.setState({ username });
    }

    return isFormValid;
  }

  resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(this.state));

    /*
    loop through each item in state and if it's safe to assume that only
    form values have an 'isValid' property, we can use that to reset their
    validation states and keep their existing value property. This process
    makes it easy to set all validation states on form inputs in case the number
    of fields on our form grows in the future.
    */
    Object.keys(state).map((key) => {
      if (state[key].hasOwnProperty('isValid')) {
        state[key].isValid = true;
        state[key].message = { ar: '', en: '' };
      }
    });

    return state;
  };

  handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { username } = this.state;
    const { checkIfAccountExist: checkIfAccountExistAction, history } = this.props;

    // reset states before the validation procedure is run.
    const restValidationState = this.resetValidationStates();

    this.setState(restValidationState, () => {
      if (this.formIsValid()) {
        const finalUsername = username.value.includes('966')
          ? username.value
          : `966${username.value}`;

        checkIfAccountExistAction(finalUsername, history);
      }
    });
  };

  render() {
    const { username } = this.state;
    const {
      isErrorCheckIfAccountExist,
      checkIfAccountExistErrorMessage,
      isFetchingCheckIfAccountExist,
      isSuccessCheckIfAccountExist,
      history,
    } = this.props;
    return (
      <LoginComponent
        username={username}
        onClick={this.handleClick}
        onUsernameFieldChange={this.onUsernameFieldChange}
        isFetchingCheckIfAccountExist={isFetchingCheckIfAccountExist}
        isSuccessCheckIfAccountExist={isSuccessCheckIfAccountExist}
        isErrorCheckIfAccountExist={isErrorCheckIfAccountExist}
        checkIfAccountExistErrorMessage={checkIfAccountExistErrorMessage}
        history={history}
      />
    );
  }
}

LoginContainer.displayName = 'LoginContainer';

const mapStateToProps = (state) => ({
  isFetchingCheckIfAccountExist: state.login.checkAccountExistBefore.isFetching,
  isSuccessCheckIfAccountExist: state.login.checkAccountExistBefore.isSuccess,
  isErrorCheckIfAccountExist: state.login.checkAccountExistBefore.isFail.isError,
  checkIfAccountExistErrorMessage: state.login.checkAccountExistBefore.isFail.message,
});

const actionCreators = {
  checkIfAccountExist,
};

export default connect(mapStateToProps, actionCreators)(LoginContainer);
