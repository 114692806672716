import React from 'react';
import { Link } from 'react-router-dom';
import {
  faAlignJustify,
  faClipboardList,
  faConciergeBell,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Footer.css';

export default function FooterComponent(props) {
  const { t } = useTranslation('common');
  const classes = 'btn white pb-4';
  const { url: currentUrl } = props;
  return (
    <div className="footer footer-rounded-top">
      <div className="flex-fill">
        <Link className={`${classes} ${currentUrl === 'services' ? 'white-active' : ''}`} to="/">
          <Col>
            <FontAwesomeIcon icon={faConciergeBell} size="lg" />
          </Col>
          {t('links.Services')}
        </Link>
      </div>
      <div className="flex-fill">
        <Link
          className={`${classes} ${currentUrl === 'requests' ? 'white-active' : ''}`}
          to="/requests"
        >
          <Col>
            <FontAwesomeIcon icon={faClipboardList} size="lg" />
          </Col>
          {t('links.Requests')}
        </Link>
      </div>
      <div className="flex-fill">
        <Link
          className={`${classes} ${currentUrl === 'others' ? 'white-active' : ''}`}
          to="/others"
        >
          <Col>
            <FontAwesomeIcon icon={faAlignJustify} size="lg" />
          </Col>
          {t('links.Others')}
        </Link>
      </div>
    </div>
  );
}
