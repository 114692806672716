import React from 'react';
import _ from 'lodash';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RequestInfoHeader from '../RequestInfoHeader';
import TopSection from './TopSection';
import ArrivalActivitySection from './ArrivalActivitySection';
import InvoiceSection from './InvoiceSection';
import Map from './Map';
import ButtonsSection from './ButtonsSection';
import { pricingListConstant } from '../../constants';
import ConfirmRequestPostpone from '../ConfirmRequestPostponeModal';
import HeaderComponent from '../_shared/Header';

export default function RequestDetailsComponent(props) {
  const { i18n } = useTranslation('common');
  const {
    request,
    requestFromFirebase,
    request: {
      _id: requestId,
      createdAt,
      field,
      requestPrettyId,
      status,
      workersWage,
      partsCost,
      total,
      discount,
      totalAfterDiscount,
      moneyTakenFromCustomerCredits,
      deliveryCostOfParts,
      worker,
      promoCode,
      arrivalActivity,
      location,
      vatValue,
    },
    addWorkerToFavorite,
    showPostponeModal,
    insuranceCalc,
    toggleCancellationModal,
    showCancellationModal,
    history,
    isFetchingAddWorkerToFavorites,
    isErrorAddWorkerToFavorites,
    addWorkerToFavoritesErrorMessage,
    togglePostponeModal,
    isErrorConfirmThePostponeRequest,
    confirmThePostponeRequestErrorMessage,
    confirmThePostponeRequest,
    isErrorGetRequestDetails,
    getRequestDetailsErrorMessage,
    rejectTheInvoice,
    isFetchingRejectTheInvoice,
    isErrorRejectTheInvoice,
    errorMessageRejectTheInvoice,
    isFetchingCreateOrderForTheInvoiceInStcPay,
    isErrorCreateOrderForTheInvoiceInStcPay,
    errorMessageCreateOrderForTheInvoiceInStcPay,
    createOrderForTheInvoiceInStcPay,
  } = props;

  return (
    <>
      {!_.isEmpty(isErrorGetRequestDetails) && (
        <Row className="w-100">
          <Col className="p-0">
            <Alert variant="danger" className="m-2">
              <h1>{getRequestDetailsErrorMessage[i18n.language]}</h1>
            </Alert>
          </Col>
        </Row>
      )}
      <flex>
        <div className="outer">
          <div className="two">
            <HeaderComponent history={history} />
            <RequestInfoHeader field={field} requestPrettyId={requestPrettyId} />
            <TopSection
              status={status}
              requestFromFirebase={requestFromFirebase}
              worker={worker}
              createdAt={createdAt}
              promoCode={promoCode}
            />

            {requestFromFirebase &&
              requestFromFirebase.status === 'active' &&
              requestFromFirebase.workersWage === 0 && (
                <ArrivalActivitySection arrivalActivity={arrivalActivity} />
              )}

            {((!_.isEmpty(requestFromFirebase.paymentStatuses) &&
              _.last(requestFromFirebase.paymentStatuses).status === 'requested') ||
              ['done', 'reviewed'].includes(status)) && (
              <InvoiceSection
                partsCost={partsCost}
                total={total}
                totalAfterDiscount={totalAfterDiscount}
                vatValue={vatValue}
                requestFromFirebase={requestFromFirebase}
                workersWage={workersWage}
                discount={discount}
                moneyTakenFromCustomerCredits={moneyTakenFromCustomerCredits}
                deliveryCostOfParts={deliveryCostOfParts}
              />
            )}

            {((requestFromFirebase &&
              ['active', 'done', 'reviewed', 'canceled', 'postponed'].includes(
                requestFromFirebase.status,
              )) ||
              ['active', 'done', 'reviewed', 'canceled', 'postponed'].includes(status)) && (
              <Row className="p-0 text-center">
                <Col className="p-0">{location && <Map location={location} />}</Col>
              </Row>
            )}

            <ButtonsSection
              history={history}
              request={request}
              showCancellationModal={showCancellationModal}
              isFetchingRejectTheInvoice={isFetchingRejectTheInvoice}
              status={status}
              workersWage={workersWage}
              requestFromFirebase={requestFromFirebase}
              worker={worker}
              addWorkerToFavorite={addWorkerToFavorite}
              addWorkerToFavoritesErrorMessage={addWorkerToFavoritesErrorMessage}
              errorMessageRejectTheInvoice={errorMessageRejectTheInvoice}
              field={field}
              isErrorAddWorkerToFavorites={isErrorAddWorkerToFavorites}
              isErrorRejectTheInvoice={isErrorRejectTheInvoice}
              isFetchingAddWorkerToFavorites={isFetchingAddWorkerToFavorites}
              pricingListConstant={pricingListConstant}
              rejectTheInvoice={rejectTheInvoice}
              requestId={requestId}
              toggleCancellationModal={toggleCancellationModal}
              isFetchingCreateOrderForTheInvoiceInStcPay={
                isFetchingCreateOrderForTheInvoiceInStcPay
              }
              isErrorCreateOrderForTheInvoiceInStcPay={isErrorCreateOrderForTheInvoiceInStcPay}
              errorMessageCreateOrderForTheInvoiceInStcPay={
                errorMessageCreateOrderForTheInvoiceInStcPay
              }
              createOrderForTheInvoiceInStcPay={createOrderForTheInvoiceInStcPay}
            />

            <Row>
              <Col className="space" />
            </Row>

            <ConfirmRequestPostpone
              request={request}
              show={showPostponeModal}
              togglePostponeModal={togglePostponeModal}
              requestFromFirebase={requestFromFirebase}
              isErrorConfirmThePostponeRequest={isErrorConfirmThePostponeRequest}
              confirmThePostponeRequestErrorMessage={confirmThePostponeRequestErrorMessage}
              confirmThePostponeRequest={confirmThePostponeRequest}
            />
          </div>
        </div>
      </flex>
    </>
  );
}
