import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './CancelRequestModal.css';

export default function CancellationReasonsListModal(props) {
  const {
    isModelOpen,
    toggleCancellationModal,
    configs: {
      enums: { cancellingActiveRequestReasonsAsArray = [] },
    },
    cancellationReason,
    handelReasonSelect,
  } = props;
  const { t, i18n } = useTranslation('common');

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModelOpen}
      onHide={toggleCancellationModal}
      // backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        {t('')}
        <Form>
          {cancellingActiveRequestReasonsAsArray.map((reason) => (
            <div key={`inline-radio-${reason.code}`} className="mb-3">
              <Form.Check
                inline
                label={reason.messages[i18n.language]}
                type="radio"
                className="mr-3"
                id={`${reason.code}`}
                checked={cancellationReason === reason.code}
                name="reason"
                data-code={reason.code}
                onChange={handelReasonSelect}
              />
            </div>
          ))}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
