import { usersConstants } from '../../constants';

const initialState = {
  userDetails: {},
  addWorkerToFavorite: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  rateWorker: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
  getUserDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export function users(state = initialState, action) {
  switch (action.type) {
    case usersConstants.ADD_WORKER_TO_FAVORITE_REQUEST:
      return {
        ...state,
        addWorkerToFavorite: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.ADD_WORKER_TO_FAVORITE_SUCCESS:
      return {
        ...state,
        addWorkerToFavorite: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.ADD_WORKER_TO_FAVORITE_FAILURE:
      return {
        ...state,
        addWorkerToFavorite: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: { ar: action.error.message, en: action.error.messageEn },
          },
        },
      };

    case usersConstants.RATE_WORKER_REQUEST:
      return {
        ...state,
        rateWorker: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.RATE_WORKER_SUCCESS:
      return {
        ...state,
        rateWorker: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.RATE_WORKER_FAILURE:
      return {
        ...state,
        rateWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case usersConstants.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        getUserDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.user,
        getUserDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    case usersConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getUserDetails: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: action.error.message,
              en: action.error.messageEn,
            },
          },
        },
      };

    case usersConstants.CLEAR:
      return initialState;

    default:
      return state;
  }
}
