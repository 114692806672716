import React from 'react';
import { Alert } from 'react-bootstrap';

import './Loading.css';

export default function LoadingComponent() {
  return (
    <Alert variant="light" className="col d-flex align-items-center justify-content-center m-0">
      <div className="vertical-center">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          width={100}
          height={100}
          alt="logo"
        />
        <div className="loading">
          <div className="loader" />
        </div>
      </div>
    </Alert>
  );
}
