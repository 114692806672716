import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { requestsActions } from '../../store/actions';

function StcPayInAppPaymentSuccessComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    isErrorConfirmOrderForTheInvoiceInStcPay,
    confirmOrderForTheInvoiceInStcPayErrorMessage,
  } = props;

  return (
    <Col className="backgroundImage text-white d-flex align-items-center justify-content-center text-center">
      <Row>
        <Col xs={12} md={12}>
          <Image src={`${process.env.PUBLIC_URL}/images/spinner.gif`} width={200} height={200} />
        </Col>
        <Col xs={12} md={12}>
          <span>{t('confirmingOrderWithStcPay')}</span>
        </Col>
        {isErrorConfirmOrderForTheInvoiceInStcPay && (
          <Col xs={12} md={12}>
            <span className="text-danger">{confirmOrderForTheInvoiceInStcPayErrorMessage[i18n.language]}</span>
          </Col>
        )}
      </Row>
    </Col>
  );
}

class StcPayInAppPaymentSuccessContainer extends Component {
  componentDidMount() {
    const {
      confirmOrderInStcPay,
      location: { search },
      history,
    } = this.props;
    const query = new URLSearchParams(search);
    const merchantOrderNo = query.get('merchantPaymentReference');
    const STCPayPaymentRef = query.get('paymentReference');

    confirmOrderInStcPay(STCPayPaymentRef, merchantOrderNo, history);
  }

  render() {
    const {
      isErrorConfirmOrderForTheInvoiceInStcPay,
      confirmOrderForTheInvoiceInStcPayErrorMessage,
    } = this.props;
    return (
      <StcPayInAppPaymentSuccessComponent
        isErrorConfirmOrderForTheInvoiceInStcPay={isErrorConfirmOrderForTheInvoiceInStcPay}
        confirmOrderForTheInvoiceInStcPayErrorMessage={
          confirmOrderForTheInvoiceInStcPayErrorMessage
        }
      />
    );
  }
}
StcPayInAppPaymentSuccessContainer.displayName = 'StcPayInAppPaymentSuccessContainer';

const mapStateToProps = (state) => ({
  isFetchingConfirmOrderForTheInvoiceInStcPay:
    state.requests.confirmOrderForTheInvoiceInStcPay.isFetching,
  isErrorConfirmOrderForTheInvoiceInStcPay:
    state.requests.confirmOrderForTheInvoiceInStcPay.isFail.isError,
  confirmOrderForTheInvoiceInStcPayErrorMessage:
    state.requests.confirmOrderForTheInvoiceInStcPay.isFail.message,
});

const actionCreators = {
  confirmOrderInStcPay: requestsActions.confirmOrderInStcPay,
};

export default connect(mapStateToProps, actionCreators)(StcPayInAppPaymentSuccessContainer);
