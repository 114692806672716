import { connect } from 'react-redux';
import React, { Component } from 'react';
import './PostponedRequests.css';

class PostponedRequests extends Component {
  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return state;
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(PostponedRequests);
