import React, { Component } from 'react';
import { connect } from 'react-redux';
import './WorkerDetails.css';
import { Alert, Col, Spinner } from 'react-bootstrap';
import HeaderComponent from '../../components/_shared/Header';

class WorkerDetailsContainer extends Component {
  render() {
    const { history } = this.props;

    return (
      <Col>
        <HeaderComponent history={history} />
        <Alert variant="warning" className="text-center mt-3">
          <Spinner animation="grow" />
          <hr />
          <Alert.Heading>Worker Details</Alert.Heading>
        </Alert>
      </Col>
    );
  }
}

WorkerDetailsContainer.displayName = 'WorkerDetailsContainer';

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedApp = connect(mapState, actionCreators)(WorkerDetailsContainer);
export { connectedApp as WorkerDetailsContainer };
