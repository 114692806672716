export const usersConstants = {
  ADD_WORKER_TO_FAVORITE_REQUEST: 'ADD_WORKER_TO_FAVORITE_REQUEST',
  ADD_WORKER_TO_FAVORITE_SUCCESS: 'ADD_WORKER_TO_FAVORITE_SUCCESS',
  ADD_WORKER_TO_FAVORITE_FAILURE: 'ADD_WORKER_TO_FAVORITE_FAILURE',

  RATE_WORKER_REQUEST: 'RATE_WORKER_REQUEST',
  RATE_WORKER_SUCCESS: 'RATE_WORKER_SUCCESS',
  RATE_WORKER_FAILURE: 'RATE_WORKER_FAILURE',

  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  CLEAR: 'CLEAR_USERS',
};
