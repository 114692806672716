import { loginConstants } from '../../constants';

const initialState = {
  username: undefined,
  checkAccountExistBefore: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: { ar: '', en: '' } },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const { username, err } = payload;

  switch (type) {
    case loginConstants.ACCOUNT_EXIST_BEFORE: {
      return {
        ...state,
        username,
        checkAccountExistBefore: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    }
    case loginConstants.ACCOUNT_EXIST_BEFORE_SUCCESS: {
      return {
        ...state,
        checkAccountExistBefore: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: { ar: '', en: '' } },
        },
      };
    }
    case loginConstants.ACCOUNT_EXIST_BEFORE_FAIL: {
      return {
        ...state,
        checkAccountExistBefore: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: {
              ar: err.message,
              en: err.messageEn,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
