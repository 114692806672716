import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.log('error', error);
    console.log('errorInfo', errorInfo);
    // send error To Slack;
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <div>Something Went Wrong</div>;
    }

    return children;
  }
}

export default ErrorBoundary;
