import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row } from 'react-bootstrap';
import React from 'react';
import AppliedWorkerCard from './AppliedWorkerCard';
import RequestInfoHeader from '../RequestInfoHeader';
import CancellationReasonModalComponent from '../CancellationReasonModal';
import Footer from '../_shared/Footer';
import LoadingComponent from '../_shared/Loading';

import './ChooseWorkerFromApplied.css';
import HeaderComponent from "../_shared/Header";

export default function ChooseWorkerHeaderTranslationComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    handleShow,
    appliedWorkers,
    acceptWorker,
    request: { field, requestPrettyId },
    isErrorGetRequestDetailsFromFirebase,
    getRequestDetailsFromFirebaseErrorMessage,
    history,
    isFetchingGetRequestDetailsFromFirebase,
    isModelOpen,
    configs,
    handleClose,
    isReasonCommentOpen,
    cancellationReasonComment,
    handelReasonCommentChange,
    handelReasonSelect,
    isFetchingCancelRequest,
    isErrorCancelRequest,
    cancelRequestErrorMessage,
    cancelRequest,
  } = props;

  const renderAppliedWorkers = () => {
    return appliedWorkers.length ? (
      <>
        {appliedWorkers.map((worker) => (
          <AppliedWorkerCard key={worker._id} worker={worker} acceptWorker={acceptWorker} />
        ))}
      </>
    ) : (
      <Row className="p-2 text-center">
        <Col className="p-1">
          <Image src={`${process.env.PUBLIC_URL}/images/chooseGif.gif`} width={200} height={200} />
        </Col>
      </Row>
    );
  };

  if (isFetchingGetRequestDetailsFromFirebase) {
    return <LoadingComponent />;
  }

  return (
    <flex>
      <div className="outer">
        <div className="two">
          <HeaderComponent history={history} />
          <RequestInfoHeader history={history} field={field} requestPrettyId={requestPrettyId} />
          {renderAppliedWorkers()}
          {isErrorGetRequestDetailsFromFirebase && (
            <span>{getRequestDetailsFromFirebaseErrorMessage[i18n.language]}</span>
          )}
          <Row className="justify-content-center m-2 p-3">
            <Button
              size="lg"
              block
              variant=""
              className="mb-2 gray-background"
              onClick={handleShow}
            >
              {t('ChooseWorkerPage.cancelRequestButtonText')}
            </Button>
          </Row>
        </div>
        <div className="three">
          <CancellationReasonModalComponent
            isModelOpen={isModelOpen}
            handleClose={handleClose}
            appliedWorkers={appliedWorkers}
            configs={configs}
            isReasonCommentOpen={isReasonCommentOpen}
            cancellationReasonComment={cancellationReasonComment}
            handelReasonCommentChange={handelReasonCommentChange}
            cancelRequest={cancelRequest}
            handelReasonSelect={handelReasonSelect}
            isFetchingCancelRequest={isFetchingCancelRequest}
            isErrorCancelRequest={isErrorCancelRequest}
            cancelRequestErrorMessage={cancelRequestErrorMessage}
          />
          <Footer url="choose-worker" />
        </div>
      </div>
    </flex>
  );
}
