import { usersConstants } from '../../constants';
import { usersService } from '../../services';
import { Status } from '../../services/api';

function addWorkerToFavorite(id) {
  return async (dispatch) => {
    dispatch(request());
    const response = await usersService.addWorkerToFavorite(id);

    if (Status.isSuccess(response.status)) {
      return dispatch(success());
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: usersConstants.ADD_WORKER_TO_FAVORITE_REQUEST };
  }

  function success() {
    return { type: usersConstants.ADD_WORKER_TO_FAVORITE_SUCCESS };
  }

  function failure(error) {
    return { type: usersConstants.ADD_WORKER_TO_FAVORITE_FAILURE, error };
  }
}

function rateWorker(payload, requestPrettyId, history) {
  return async (dispatch) => {
    dispatch(request());
    const response = await usersService.rateWorker(payload);

    if (Status.isSuccess(response.status)) {
      dispatch(success());
      return history.push(`/request-details/${requestPrettyId}`);
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: usersConstants.RATE_WORKER_REQUEST };
  }

  function success() {
    return { type: usersConstants.RATE_WORKER_SUCCESS };
  }

  function failure(error) {
    return { type: usersConstants.RATE_WORKER_FAILURE, error };
  }
}

function getUserDetails(userId) {
  return async (dispatch) => {
    dispatch(request());
    const response = await usersService.getUserDetails(userId);
    if (Status.isSuccess(response.status)) {
      const { response: userObject } = response;
      return dispatch(success(userObject));
    }

    const { response: message } = response;

    return dispatch(failure(message));
  };

  function request() {
    return { type: usersConstants.GET_USER_DETAILS_REQUEST };
  }

  function success(userObject) {
    return {
      type: usersConstants.GET_USER_DETAILS_SUCCESS,
      user: userObject,
    };
  }

  function failure(error) {
    return { type: usersConstants.GET_USER_DETAILS_FAILURE, error };
  }
}

export const usersActions = {
  addWorkerToFavorite,
  rateWorker,
  getUserDetails,
};
