import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appliedWorkersActions, getAllConfigurations, requestsActions } from '../../store/actions';
import ChooseWorkerFromAppliedComponent from '../../components/ChooseWorkerFromApplied';

class ChooseWorkerFromAppliedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      isReasonCommentOpen: false,
      cancellationReason: null,
      cancellationReasonComment: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getRequestDetailsByPrettyIdFromFirebase,
      getAllConfigurations,
    } = this.props;

    getRequestDetailsByPrettyIdFromFirebase(id);
    getAllConfigurations();
  }

  componentDidUpdate() {
    const {
      requestDetailsFromFirebase: { status, requestPrettyId },
      isSuccessGetRequestDetailsFromFirebase,
      history,
    } = this.props;
    console.log('status', status);
    if (isSuccessGetRequestDetailsFromFirebase && status === 'active') {
      history.push(`/request-details/${requestPrettyId}`);
    }
    if (isSuccessGetRequestDetailsFromFirebase && status === 'done') {
      history.push(`/request-details/${requestPrettyId}/rate`);
    }
    if (
      isSuccessGetRequestDetailsFromFirebase &&
      (['postponed', 'reviewed', 'timed-out', 'canceled'].includes(status) || !status)
    ) {
      history.push('/');
    }
  }

  acceptWorker = (workerId) => {
    const {
      requestDetailsFromFirebase: { _id: requestId, requestPrettyId },
      history,
      acceptAppliedWorker,
    } = this.props;

    const data = { requestId, workerId };

    acceptAppliedWorker(data, requestPrettyId, history);
  };

  cancelRequest = () => {
    const {
      requestDetailsFromFirebase: { _id: requestId },
      cancelRequest,
      history,
    } = this.props;
    const { cancellationReason, cancellationReasonComment } = this.state;

    const data = { requestId, cancellationReason, cancellationReasonComment };

    cancelRequest(data, history);
  };

  handelReasonSelect = (e) => {
    const { code } = e.target.dataset;
    this.setState({ cancellationReason: code });
    if (code === 'other') {
      this.setState({ isReasonCommentOpen: true });
    } else {
      this.setState({ isReasonCommentOpen: false });
    }
  };

  handleClose = () =>
    this.setState({
      isModelOpen: false,
      isReasonCommentOpen: false,
      cancellationReason: null,
      cancellationReasonComment: '',
    });

  handleShow = () => this.setState({ isModelOpen: true });

  handelReasonCommentChange = (e) => this.setState({ cancellationReasonComment: e.target.value });

  render() {
    const {
      configs,
      requestDetailsFromFirebase,
      requestDetailsFromFirebase: { appliedWorkers = [] },
      isFetchingGetRequestDetailsFromFirebase,
      isErrorGetRequestDetailsFromFirebase,
      getRequestDetailsFromFirebaseErrorMessage,
      isFetchingCancelRequest,
      isErrorCancelRequest,
      cancelRequestErrorMessage,
      history,
    } = this.props;
    const { isModelOpen, isReasonCommentOpen, cancellationReasonComment } = this.state;

    return (
      <ChooseWorkerFromAppliedComponent
        appliedWorkers={appliedWorkers}
        handleShow={this.handleShow}
        acceptWorker={this.acceptWorker}
        request={requestDetailsFromFirebase}
        isErrorGetRequestDetailsFromFirebase={isErrorGetRequestDetailsFromFirebase}
        getRequestDetailsFromFirebaseErrorMessage={getRequestDetailsFromFirebaseErrorMessage}
        history={history}
        configs={configs}
        isFetchingCancelRequest={isFetchingCancelRequest}
        isErrorCancelRequest={isErrorCancelRequest}
        cancelRequestErrorMessage={cancelRequestErrorMessage}
        isFetchingGetRequestDetailsFromFirebase={isFetchingGetRequestDetailsFromFirebase}
        isModelOpen={isModelOpen}
        isReasonCommentOpen={isReasonCommentOpen}
        cancellationReasonComment={cancellationReasonComment}
        handleClose={this.handleClose}
        handelReasonCommentChange={this.handelReasonCommentChange}
        handelReasonSelect={this.handelReasonSelect}
        cancelRequest={this.cancelRequest}
      />
    );
  }
}

ChooseWorkerFromAppliedContainer.displayName = 'ChooseWorkerFromAppliedContainer';

const mapStateToProps = (state) => ({
  configs: state.services.configs,
  requestDetailsFromFirebase: state.requests.detailsFromFirebase,

  isFetchingGetRequestDetailsFromFirebase: state.requests.getRequestDetailsFromFirebase.isFetching,
  isSuccessGetRequestDetailsFromFirebase: state.requests.getRequestDetailsFromFirebase.isSuccess,
  isErrorGetRequestDetailsFromFirebase: state.requests.getRequestDetailsFromFirebase.isFail.isError,
  getRequestDetailsFromFirebaseErrorMessage:
    state.requests.getRequestDetailsFromFirebase.isFail.message,

  isFetchingCancelRequest: state.requests.cancelRequest.isFetching,
  isSuccessCancelRequest: state.requests.cancelRequest.isSuccess,
  isErrorCancelRequest: state.requests.cancelRequest.isFail.isError,
  cancelRequestErrorMessage: state.requests.cancelRequest.isFail.message,
});

const actionCreators = {
  getAllConfigurations,
  getRequestDetailsByPrettyIdFromFirebase: requestsActions.getRequestDetailsByPrettyIdFromFirebase,
  acceptAppliedWorker: appliedWorkersActions.acceptWorker,
  cancelRequest: requestsActions.cancelRequest,
};

export default connect(mapStateToProps, actionCreators)(ChooseWorkerFromAppliedContainer);
