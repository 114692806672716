import { Api, Status, Urls } from '../../services/api';
import { signUpConstants } from '../../constants';

const signUp = (userInfo, historyPush) => ({
  type: signUpConstants.SIGN_UP,
  userInfo,
  historyPush,
});

const signUpSuccess = (user) => ({
  type: signUpConstants.SIGN_UP_SUCCESS,
  user,
});

const signUpFail = (err) => ({
  type: signUpConstants.SIGN_UP_FAIL,
  err,
});

export const signUpCustomer = (data, history) => {
  return async (dispatch) => {
    dispatch(signUp());
    const { user } = Urls;
    const url = `${user.signUp}`;
    const api = new Api();

    const response = await api.post(url, data);

    if (Status.isSuccess(response.status)) {
      const { response: customer } = response;
      dispatch(signUpSuccess(customer));
      return history.push('/otp');
    }

    const { response: message } = response;

    return dispatch(signUpFail(message));
  };
};
