import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormControl } from 'react-bootstrap';
import React from 'react';

import './Otp.css';

export default function OtpComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    password,
    onPasswordFieldChange,
    handleSubmitLoginForm,
    time,
    isFetchingLogin,
    isErrorLogin,
    loginErrorMessage,
    isErrorCheckAccountExistBefore,
    checkAccountExistBeforeErrorMessage,
  } = props;

  return (
    <Form noValidate>
      <Form.Row className="justify-content-center p-lg-5">
        <Col>
          <h1>{t('AppName')}</h1>
        </Col>
      </Form.Row>
      <Form.Row className="justify-content-center p-lg-5">
        <Col xs={4} md={4}>
          <Form.Group>
            <FormControl
              type="number"
              name="password"
              value={password.value}
              onChange={onPasswordFieldChange}
              readOnly={isFetchingLogin}
              required
              autoFocus
            />
            <Form.Control.Feedback type="invalid">{t('InvalidOtp')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Col xs={12} md={12}>
        <span className="text-danger">{password.message[i18n.language]}</span>
        {isErrorLogin && <span className="text-danger">{loginErrorMessage[i18n.language]}</span>}
        {isErrorCheckAccountExistBefore && (
          <span className="text-danger">{checkAccountExistBeforeErrorMessage[i18n.language]}</span>
        )}
      </Col>
      <Form.Row className="justify-content-center">
        <Col>
          <Button
            className="mb-2"
            type="submit"
            disabled={isFetchingLogin}
            onClick={!isFetchingLogin ? handleSubmitLoginForm : null}
          >
            {isFetchingLogin ? t('OtpButtonLoadingText') : t('OtpButtonText')}
          </Button>
        </Col>
      </Form.Row>
      <Form.Row className="justify-content-center">
        <Form.Text className="text-muted">
          {t('ReSendOtpText')} {time} {t('ReSendOtpTimeUnit')}
        </Form.Text>
      </Form.Row>
    </Form>
  );
}
