import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ChangeLanguageModal.css';

export default function ChangeLanguageComponent(props) {
  const { t, i18n } = useTranslation('common');
  const { show, toggleChangeLanguageModal, language, onChangeInputValue } = props;

  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={toggleChangeLanguageModal}
      centered
      size="sm"
      dialogClassName="change-language-modal-dialog modal-dialog-centered"
    >
      <Modal.Body>
        <Form.Label>{t('othersPage.changeLanguageLabel')} </Form.Label>
        <Form.Control
          as="select"
          name="language"
          value={language}
          onChange={(event) => {
            onChangeInputValue(event);
            i18n.changeLanguage(event.target.value);
          }}
          className="mr-sm-2"
          id="inlineFormCustomSelect"
          custom
        >
          <option value="ar">{t('othersPage.arabicLanguageOptionText')}</option>
          <option value="en">{t('othersPage.englishLanguageOptionText')}</option>
        </Form.Control>
      </Modal.Body>
    </Modal>
  );
}
