import { apiUrl } from '../../config/index';

const BaseUrls = {
  apiUrl,
};

const Urls = {
  baseUrl: BaseUrls.apiUrl,
  user: {
    checkAccountExist: `${BaseUrls.apiUrl}/users/oneTimePassword`,
    login: `${BaseUrls.apiUrl}/users/login`,
    signUp: `${BaseUrls.apiUrl}/users/customerSignUp`,
    deleteUser: `${BaseUrls.apiUrl}/users/deleteUser`,
  },
  requests: {
    general: `${BaseUrls.apiUrl}/requests`,
    getAllCustomerRequests: `${BaseUrls.apiUrl}/requests/customer`,
    getRequestDetailsByPrettyId: `${BaseUrls.apiUrl}/requests/prettyIdV2`,
    cancel: `${BaseUrls.apiUrl}/requests/cancel`,
    getRequestsCounts: `${BaseUrls.apiUrl}/requests/counts`,
    finishRequest: `${BaseUrls.apiUrl}/requests/costDetails/confirm`,
    activate: `${BaseUrls.apiUrl}/requests/accept`,
    confirmThePostponeRequest: `${BaseUrls.apiUrl}/requests/postpone/confirm`,
    createOrderForTheInvoiceInStcPayUrl: `${BaseUrls.apiUrl}/transactions/webapp/createOrder/stcpay`,
    confirmOrderInStcPayUrl: `${BaseUrls.apiUrl}/transactions/webapp/confirmOrder/stcpay`,
    rejectTheInvoiceUrl: `${BaseUrls.apiUrl}/requests/costDetails/confirm`,
  },
  reviews: {
    general: `${BaseUrls.apiUrl}/reviews`,
  },
  promoCodes: {
    getByPromoCode: `${BaseUrls.apiUrl}/promoCodes/getByPromoCode`,
  },
  configs: {
    url: `${BaseUrls.apiUrl}/misc/configs`,
  },
  customersAds: {
    getAllCustomersAds: `${BaseUrls.apiUrl}/ads`,
  },
  offers: {
    getAllOffers: `${BaseUrls.apiUrl}/offers`,
  },
  customers: {
    addWorkerToFavorite: `${BaseUrls.apiUrl}/users/favorite`,
    reviewWorker: `${BaseUrls.apiUrl}/users/reviewWorker`,
    getUserDetailsUrl: `${BaseUrls.apiUrl}/users/account`,
  },
};

export default Urls;
