import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ConfirmRequestPostponeModal.css';

export default function ConfirmRequestPostponeComponent(props) {
  const { t, i18n } = useTranslation('common');
  const {
    request: { _id: requestId },
    show,
    togglePostponeModal,
    isErrorConfirmThePostponeRequest,
    confirmThePostponeRequestErrorMessage,
    confirmThePostponeRequest,
  } = props;

  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={togglePostponeModal}
      centered
      size="sm"
      dialogClassName="confirm-postpone-modal-dialog modal-dialog-centered"
    >
      <Modal.Body>
        <h5>{t('RequestDetailsPage.postponeModal.titleText')}</h5>
        {isErrorConfirmThePostponeRequest && (
          <span className="text-danger">
            {confirmThePostponeRequestErrorMessage[i18n.language]}
          </span>
        )}
      </Modal.Body>
      <Modal.Footer className="confirm-postpone-modal-footer">
        <Col>
          <Row>
            <Col xs={6} md={6} className="p-1">
              <Button
                variant="primary"
                className="btn-block"
                onClick={() => confirmThePostponeRequest(requestId, true)}
              >
                {t('confirmModalAcceptanceButtonText')}
              </Button>
            </Col>
            <Col xs={6} md={6} className="p-1">
              <Button
                variant="secondary"
                className="btn-block"
                onClick={() => confirmThePostponeRequest(requestId, false)}
              >
                {t('confirmModalCancellationButtonText')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
