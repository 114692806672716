import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ActiveRequestsWithoutLogin.css';

export default function ActiveRequestsWithoutLoginComponent() {
  const { t } = useTranslation('common');

  return (
    <Row className="p-2 text-center">
      <Col xs={12} md={12} className="p-1">
        <Image src={`${process.env.PUBLIC_URL}/images/spinner.gif`} width={200} height={200} />
      </Col>
      <Col className="p-2" xs={12} md={12}>
        <span>{t('RequestsPage.youShouldLogin')}</span>
      </Col>
      <Col className="p-2" xs={12} md={12}>
        <Link to="/login" className="btn btn-lg btn-fanni">
          {t('LoginButtonText')}
        </Link>
      </Col>
    </Row>
  );
}
