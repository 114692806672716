import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { Alert, Image, Row, Col } from 'react-bootstrap';

function AutoCompleteTranslationComponent(props) {
  const { t } = useTranslation('common');
  const { onPlaceSelectedFromAutoComplete, field } = props;
  return (
    <Row>
      <Col xs={8} md={8} className="p-0 pt-3">
        <Autocomplete
          style={{
            width: '100%',
            height: '40px',
            borderRadius: '10px',
          }}
          onPlaceSelected={onPlaceSelectedFromAutoComplete}
          types={['(regions)']}
          placeholder={t('servicesPage.mapSearchInputPlaceholderText')}
        />
      </Col>
      <Col className="p-2 text-center">
        <div className="circle">
          <Image
            className="to-be-white"
            src={`${process.env.PUBLIC_URL}/images/services/${field}.png`}
            width={35}
            height={35}
          />
        </div>
        <br />
        <span>{t(`Fields.${field}`)}</span>
      </Col>
    </Row>
  );
}

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    const { markerPosition } = this.props;
    this.state = {
      markerPosition: {
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      },
      isGeoLocationPermissionEnabled: true,
    };
  }

  componentDidMount = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.onChooseLocation(lat, lng);
        },
        (positionError) => {
          console.log('positionError', positionError);
          switch (positionError.code) {
            // PERMISSION_DENIED
            case 1:
              console.log('Permission denied');
              this.setState({ isGeoLocationPermissionEnabled: false });
              break;
            // POSITION_UNAVAILABLE
            case 2:
              console.log('Permission allowed, location disabled');
              this.setState({ isGeoLocationPermissionEnabled: false });

              break;
            // TIMEOUT
            case 3:
              this.setState({ isGeoLocationPermissionEnabled: false });
              console.log('Permission allowed, timeout reached');
              break;
          }
        },
      );
    }
  };

  onChooseLocation = (newLat, newLng) => {
    const { handleLocationChange } = this.props;

    handleLocationChange(newLat, newLng);
    this.setState({
      markerPosition: {
        lat: newLat,
        lng: newLng,
      },
    });
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelectedFromAutoComplete = (place) => {
    this.onChooseLocation(place.geometry.location.lat(), place.geometry.location.lng());
  };

  handleBoundsChanged = () => {
    const mapCenter = this.mapRef.current.getCenter();
    this.onChooseLocation(mapCenter.lat(), mapCenter.lng());
  };

  render() {
    const { markerPosition: stateMarkerPosition, isGeoLocationPermissionEnabled } = this.state;
    const { field } = this.props;

    return (
      <>
        {stateMarkerPosition.lat && (
          <GoogleMap
            ref={this.mapRef}
            defaultZoom={13}
            defaultCenter={stateMarkerPosition}
            onBoundsChanged={this.handleBoundsChanged}
            defaultOptions={{
              disableDefaultUI: true,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
          >
            {field && (
              <AutoCompleteTranslationComponent
                field={field}
                onPlaceSelectedFromAutoComplete={this.onPlaceSelectedFromAutoComplete}
              />
            )}
            {/* Marker */}
            <Marker
              position={stateMarkerPosition}
              icon={{
                url: `${process.env.PUBLIC_URL}/images/pin.png`,
              }}
            />
          </GoogleMap>
        )}
        {!isGeoLocationPermissionEnabled && (
          <Alert style={{ position: 'absolute', zIndex: 99999 }} variant="danger">
            يجب السماح لاستخدام موقعك
          </Alert>
        )}
      </>
    );
  }
}
export default withScriptjs(withGoogleMap(Map));
