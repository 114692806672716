import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './RequestCard.css';

function RequestCardComponent(props) {
  const { t } = useTranslation('common');
  const {
    request: { _id, requestPrettyId, field, status, city, createdAt },
    onClick,
  } = props;
  const colorBasedOnRequestStatus = status === 'active' ? '#0000CD' : '#6a0dad';

  return (
    <Card
      className="bg-white rounded-0 m-0 pb-3"
      style={{
        borderBottom: '1px solid black',
      }}
      key={_id}
      onClick={() => onClick(requestPrettyId, status)}
    >
      <Card.Body className="p-0">
        <div className="vertical" style={{ borderColor: colorBasedOnRequestStatus }} />
        <Row className="p-2 pr-3">
          <Col className="p-0">
            {new Date(createdAt).toLocaleDateString('en-EG', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </Col>
        </Row>
        <Row className="pt-0 pr-5 pl-4 pb-0">
          <Col xs={3} className="p-0 m-0">
            <img
              className="to-be-black"
              src={`${process.env.PUBLIC_URL}/images/services/${field}.png`}
              width="25"
              height="25"
              alt=""
            />
          </Col>
          <Col xs={3} className="p-0 m-0">
            <Card.Subtitle>{t('RequestsPage.RequestCard.city')}</Card.Subtitle>
          </Col>
          <Col className="p-0 m-0">
            <Card.Subtitle>{t('RequestsPage.RequestCard.status')}</Card.Subtitle>
          </Col>
          <Col xs={1}>
            <FontAwesomeIcon icon={faAngleLeft} size="lg" key={_id} />
          </Col>
        </Row>
        <Row className="pt-0 pr-5 pl-4 pb-0">
          <Col xs={3} className="p-0 m-0">
            <Card.Subtitle>{t(`Fields.${field}`)}</Card.Subtitle>
          </Col>
          <Col xs={3} className="p-0 m-0">
            <Card.Subtitle>{t(`Cities.${city}`)}</Card.Subtitle>
          </Col>
          <Col className="p-0 m-0">
            <Card.Subtitle
              style={{
                color: colorBasedOnRequestStatus,
              }}
            >
              {field !== 'reconditioning' && status === 'pending'
                ? t('RequestsPage.RequestCard.pending_hint_looking')
                : field !== 'reconditioning'
                  ? t(`RequestStatuses.${status}`)
                  : t('RequestsPage.RequestCard.willContactYou')}
            </Card.Subtitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

RequestCardComponent.displayName = 'RequestCardComponent';

export default RequestCardComponent;
